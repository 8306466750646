// React
import React from 'react';
// Chakra
import { Input } from '@chakra-ui/react';

const EditableDateField = ({ field, value, onChange, isDisabled = false }) => {
  // Format date to YYYY-MM-DD in local timezone
  const formatDateToLocal = (date) => {
    if (!date) return '';
    const d = new Date(date);
    // Get year, month, day in local timezone and pad with zeros if needed
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0'); // months are 0-indexed
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  // Parse date from input, preserving the local timezone
  const handleDateChange = (dateString) => {
    if (!dateString) return null;
    
    // Create date in local timezone
    const [year, month, day] = dateString.split('-').map(Number);
    const localDate = new Date(year, month - 1, day); // month is 0-indexed in Date constructor
    
    return localDate;
  };

  /* ------------------------------ Render ------------------------------ */
  return (
    <Input
      type="date"
      w="100%"
      value={formatDateToLocal(value)}
      onChange={(e) => onChange(field, e.target.value ? handleDateChange(e.target.value) : null)}
      onInput={(e) => e.target.value === '' && onChange(field, null)}
      size="md"
      isDisabled={isDisabled}
    />
  );
};

export default EditableDateField;
