// React
import React, { useState, useEffect } from 'react';
// Chakra
import { Box, Center, Input, Spinner, VStack, Text } from '@chakra-ui/react';
// MdM
import SelectableList from '../../../../common/SelectableList';
import { filterDocs } from '../../../../../common/utils';
import projectSchema from '../../../../../data/schemas/project';
import useDocListLoading from '../../../../common/docs/useDocListLoading';
import tasksConfig from '../DesignerTasksConfig';

const ProjectPane = ({
  selectedProject,
  onProjectSelect,
}) => {
  /* ------------------------------ Hooks ------------------------------ */
  const [searchTerm, setSearchTerm] = useState('');
  
  const {
    listDocuments: projectDocs,
    fetchListDocuments: fetchProjectDocs,
    isLoading: isLoadingProjectDocs,
  } = useDocListLoading(tasksConfig.projects);

  /* ------------------------------ Effects ------------------------------ */
  useEffect(() => {
    fetchProjectDocs();
  }, [fetchProjectDocs]);

  /* ------------------------------ Render ------------------------------ */
  // Filter for active projects only
  const activeProjects = projectDocs.filter(doc => doc.state === 'Active');
  const filteredDocs = filterDocs(projectSchema, activeProjects, searchTerm);

  // Create list elements with "All Projects" at the beginning
  const listElements = [
    {
      key: 'all-projects',
      displayComponent: <Text fontWeight="bold" align="center">All Projects</Text>,
      original: null,
    },
    ...filteredDocs.map((doc) => ({
      key: doc.id,
      displayComponent: <tasksConfig.projects.docDisplayFormat document={doc} />,
      original: doc,
    }))
  ];

  return (
    <Box h="100%" w="100%">
      {isLoadingProjectDocs ? (
        <Center w="100%" h="100%">
          <Spinner />
        </Center>
      ) : (
        <VStack h="100%" w="100%" spacing={0}>
          <Box p={2} w="100%">
            <Input
              placeholder="Search projects (by project name or client name)..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              size="sm"
              bg="mdm_dark.200"
            />
          </Box>
          <Box w="100%" flex="1" className="selectable-list-container" overflowY="auto">
            <SelectableList
              listElements={listElements}
              selectedElementKey={selectedProject?.id || 'all-projects'}
              onElementClick={onProjectSelect}
            />
          </Box>
        </VStack>
      )}
    </Box>
  );
};

export default ProjectPane; 