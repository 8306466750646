// React
import React, { useState, useCallback, useEffect } from 'react';
// Chakra
import {
  Box, HStack, Input, IconButton, VStack, Checkbox, Spinner, Text,
} from '@chakra-ui/react';
// MdM
import { createDocument, removeDocument } from '../../../../data/documents';
import SelectableList from '../../../common/SelectableList';
import { AddIcon } from '../../../../common/icons';
import { filterDocs } from '../../../../common/utils';
import AddDocModal from './AddDocModal';
import useUser from '../../../../common/hooks/useUser';


const DocList = ({
    listDocuments,
    listConfig,
    isLoading,
    fetchListDocuments,
    selectedDocument,
    setSelectedDocument,
    defaultFields = {},
    fixedFields = {},
    addDisabled = false,
    onNewDocAdded,
    filterCallback,
    filterLabel = "Show all",
    filterChecked = false,
}) => {
  /* ------------------------------ Hooks ------------------------------ */
  const { user } = useUser();
  const [searchTerm, setSearchTerm] = useState('');
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [showFilterLabel, setShowFilterLabel] = useState(false);

  /* ------------------------------ Callbacks ------------------------------ */
  const handleAddModalSave = useCallback(async (newDoc) => {
    const newDocId = await createDocument(listConfig.collection, newDoc);
    newDoc.id = newDocId;
    fetchListDocuments();
    setIsAddModalOpen(false);
    if (onNewDocAdded) {
      onNewDocAdded(newDoc);
    }
  }, [fetchListDocuments, listConfig, onNewDocAdded]);

  const handleDocumentDelete = useCallback(async (doc) => {
    await removeDocument(listConfig.collection, doc.id);
    fetchListDocuments();
  }, [fetchListDocuments, listConfig]);

  /* ------------------------------ Effects ------------------------------ */
  useEffect(() => {
    fetchListDocuments();
  }, [fetchListDocuments]);

  /* ------------------------------ Render ------------------------------ */
  const filteredDocs = !isLoading ? filterDocs(listConfig.docSchema, listDocuments, searchTerm) : [];

  if (!listConfig) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" h="100%">
        <Spinner />
      </Box>
    );
  }

  return (
    <VStack w="100%" h="100%">
      {/* Search bar */}
      <Box p={2} w="100%">
        <HStack spacing={2}>
          {/* Filter checkbox (if callback provided) */}
          {filterCallback && (
            <Box position="relative">
              <Checkbox
                isChecked={filterChecked}
                onChange={(e) => {
                  filterCallback(e.target.checked);
                  setShowFilterLabel(false);
                }}
                size="lg"
                onMouseEnter={() => setShowFilterLabel(true)}
                onMouseLeave={() => setShowFilterLabel(false)}
              />
              {showFilterLabel && (
                <Box
                  position="absolute"
                  bottom="-50px"
                  bg="mdm_dark.200"
                  color="white"
                  p={2}
                  borderRadius="md"
                  border="2px solid"
                  borderColor="mdm_dark.300"
                  zIndex={10}
                  boxShadow="md"
                >
                  <Text fontSize="sm" whiteSpace="nowrap">{filterLabel}</Text>
                </Box>
              )}
            </Box>
          )}
          <Input
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            size="sm"
            bg="mdm_dark.200"
          />
          <IconButton
            icon={<AddIcon />}
            colorScheme="mdm_teal"
            size="md"
            aria-label="Add new document"
            onClick={() => setIsAddModalOpen(true)}
            isDisabled={addDisabled || !listConfig.docSchema.rolesWithAddAccess.includes(user?.role)}
          />
        </HStack>
      </Box>
      {/* Document List */}
      <Box w="100%" flex="1" overflowY="auto">
        <SelectableList
          listElements={filteredDocs.map((doc) => ({
            key: doc.id,
            displayComponent: <listConfig.docDisplayFormat document={doc} />,
            original: doc,
          }))}
          selectedElementKey={selectedDocument?.id}
          onElementClick={(doc) => setSelectedDocument(doc)}
          onElementDelete={(doc) => handleDocumentDelete(doc)}
          docSchema={listConfig.docSchema}
        />
      </Box>
      {/* Add Doc Modal */}
      <AddDocModal
        docSchema={listConfig.docSchema}
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onSave={handleAddModalSave}
        defaultFields={defaultFields}
        fixedFields={fixedFields}
      />
    </VStack>
  );
};

export default DocList;
