// Firebase
import { signOut } from 'firebase/auth';
import { auth } from '../../common/firebase';
// Chakra
import { Box, HStack, IconButton, Select, useToast } from '@chakra-ui/react';
// Icon
import { SignOutIcon } from '../../common/icons';
// React
import { useEffect, useState } from 'react';
// Redux
import { useDispatch } from 'react-redux';
import { setCurrentUser } from '../../redux/slices/userSlice';
// MdM
import { getDocuments } from '../../data/documents';
import useUser from '../../common/hooks/useUser';

const Header = ({ loggedOut }) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const { actualUser, user } = useUser();
  const [users, setUsers] = useState([]);

  const showUserSelect = actualUser?.role === 'admin';

  useEffect(() => {
    const loadUsers = async () => {
      try {
        const allUsers = await getDocuments('users');
        setUsers(allUsers);
      } catch (error) {
        console.error('Error loading users:', error);
        toast({
          title: 'Error loading users',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    };

    if (showUserSelect) {
      loadUsers();
    }
  }, [actualUser, toast, showUserSelect]);

  const handleUserChange = (event) => {
    const selectedUser = users.find(user => user.id === event.target.value);
    if (selectedUser) {
      dispatch(setCurrentUser(selectedUser));
    }
  };

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        toast({
          title: 'Logout successful',
          description: 'You have been logged out successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          title: 'Error during logout',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      <HStack
        width="100%"
        bg="mdm.teal_main"
        justifyContent="space-between"
        boxShadow="lg"
        position="relative"
        zIndex={2}
      >
        <Box as="img" src="/assets/MdMLongLogo64White.png" alt="Logo" height={16} p={2} />
        {!loggedOut && (
          <HStack spacing={4} mr={6}>
            {showUserSelect && (
              <Select
                value={user?.id}
                onChange={handleUserChange}
                size="sm"
              >
                {users.map(user => (
                  <option key={user.id} value={user.id}>
                    {user.displayName}
                  </option>
                ))}
              </Select>
            )}
            <IconButton
              icon={<SignOutIcon size="24px" />}
              variant="ghost"
              aria-label="Logout"
              height="36px"
              width="36px"
              onClick={handleLogout}
            />
          </HStack>
        )}
      </HStack>
    </>
  );
};

export default Header;