// React
import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

// Chakra
import { Box, Center, Spinner, VStack, Flex } from '@chakra-ui/react';

// MdM
import { getRoleConfig } from './common/routes';
import Header from './pages/Header/Header';
import Login from './pages/Login/Login';
import Sidebar from './pages/Sidebar/Sidebar';
import useUser from './common/hooks/useUser';

const App = () => {
  // ------------------------------ Hooks ------------------------------ //
  const { actualUser, user, loading } = useUser();
  
  // ------------------------------ Render ------------------------------ //
  const renderPage = () => {
    if (!actualUser) {
      return <Login />;
    }
    
    const roleConfig = getRoleConfig(user.role);

    if (!roleConfig || !roleConfig.route?.path) {
      console.error('Invalid route for role:', user.role);
      return <Navigate to="/login" replace />;
    }
    
    return (
      <Flex h="100%">
        <Sidebar userRole={user.role} />
        <Box flex={1} ml="75px" transition="margin-left 0.2s">
          <Routes>
            <Route path={roleConfig.route.path} element={roleConfig.route.element} />
            <Route path="*" element={<Navigate to={roleConfig.route.path} replace />} />
          </Routes>
        </Box>
      </Flex>
    );
  };

  return (
    <BrowserRouter>
      <VStack width="100vw" height="100vh" bg="mdm_dark.300" spacing={0}>
        <Header loggedOut={!actualUser}/>
        <Box flex={1} width="100%" overflow="auto">
          {loading ? (
            <Center height="100%">
              <Spinner size="xl" color="mdm_teal.500" />
            </Center>
          ) : (
            renderPage()
          )}
        </Box>
      </VStack>
    </BrowserRouter>
  );
}

export default App;
