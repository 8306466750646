// React
import React from 'react';
// Chakra
import { Text } from '@chakra-ui/react';
// MdM
import EditableDateField from '../../pages/common/docs/editableFields/EditableDateField';
import EditableDocRefField from '../../pages/common/docs/editableFields/EditableDocRefField';
import EditableEnumRefField from '../../pages/common/docs/editableFields/EditableEnumRefField';
import EditableNumberField from '../../pages/common/docs/editableFields/EditableNumberField';
import EditableTextField from '../../pages/common/docs/editableFields/EditableTextField';
import EditableTextBlockField from '../../pages/common/docs/editableFields/EditableTextBlockField';

const componentMapping = {
  date: EditableDateField,
  docRef: EditableDocRefField,
  enumRef: EditableEnumRefField,
  number: EditableNumberField,
  text: EditableTextField,
  text_block: EditableTextBlockField,
};

export const renderEditableField = (field, fieldSchema, value, onFieldChange, isDisabled) => {
  if (!componentMapping[fieldSchema.type]) {
    return <Text>Invalid field type: {fieldSchema.type}</Text>;
  }
  const Component = componentMapping[fieldSchema.type];
  return (
    <Component
      field={field}
      fieldSchema={fieldSchema}
      value={value}
      onChange={onFieldChange}
      isDisabled={isDisabled}
    />
  );
}; 