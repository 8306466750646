// documents.js

// Firestore
import {
  addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, updateDoc, deleteField, where,
} from 'firebase/firestore';

// MdM
import { db } from '../common/firebase';
import masterSchema from './schemas/master';

export async function getDocuments(collectionName, queryConstraints = []) {
  try {
    const collectionRef = collection(db, collectionName);
    const q = queryConstraints.length > 0
      ? query(collectionRef, ...queryConstraints)
      : collectionRef;
    const snapshot = await getDocs(q);
    // Resolve docRefs for each document
    return await Promise.all(
      snapshot.docs.map(async docSnap => {
        const rawDoc = { id: docSnap.id, ...docSnap.data() };
        const convertedDoc = convertDateFields(rawDoc, collectionName);
        return await resolveDocRefs(convertedDoc, collectionName);
      })
    );
  } catch (error) {
    console.error(`Error fetching documents from ${collectionName}:`, error);
    throw error;
  }
}

export async function getDocumentById(collectionName, documentId) {
  try {
    return await fetchDocument(collectionName, documentId, true);
  } catch (error) {
    console.error(`Error fetching document ${documentId} from ${collectionName}:`, error);
    throw error;
  }
}

export async function createDocument(collectionName, newDocument) {
  const cleanDocument = prepareDocumentData(newDocument, collectionName, false);
  const docRef = await addDoc(collection(db, collectionName), cleanDocument);
  return docRef.id;
}

export async function saveDocument(collectionName, id, newDocument) {
  const cleanDocument = prepareDocumentData(newDocument, collectionName);
  const docRef = doc(db, collectionName, id);
  await updateDoc(docRef, cleanDocument);
}

export async function removeDocument(collectionName, id) {
  try {
    // Check if the schema has cascadeDelete configuration
    const schema = masterSchema.collections[collectionName];
    if (schema && schema.cascadeDelete && schema.cascadeDelete.length > 0) {
      // Handle cascade deletion for each configured relationship
      for (const cascadeConfig of schema.cascadeDelete) {
        const { collection: targetCollection, field: targetField } = cascadeConfig;
        
        // Query for all documents in the target collection that reference this document
        const targetCollectionRef = collection(db, targetCollection);
        const q = query(targetCollectionRef, where(targetField, '==', id));
        const snapshot = await getDocs(q);
        
        // Delete each related document
        const deletePromises = snapshot.docs.map(docSnap => 
          deleteDoc(doc(db, targetCollection, docSnap.id))
        );
        
        // Wait for all deletions to complete
        await Promise.all(deletePromises);
      }
    }
    
    // Delete the main document
    const docRef = doc(db, collectionName, id);
    await deleteDoc(docRef);
  } catch (error) {
    console.error(`Error removing document ${id} from ${collectionName}:`, error);
    throw error;
  }
}

// New function: get the current user document from the "users" collection using user.uid
export async function getCurrentUserDocument(user) {
  // user should have a uid property: doc_id === user.uid
  return await getDocumentById('users', user.uid);
}

/* ------------------------------ Helpers ------------------------------ */
async function fetchDocument(collectionName, documentId, resolveRefs = false) {
  const docRef = doc(db, collectionName, documentId);
  const docSnap = await getDoc(docRef);
  if (!docSnap.exists()) return null;
  let docData = { id: docSnap.id, ...docSnap.data() };
  docData = convertDateFields(docData, collectionName);
  if (resolveRefs) {
    docData = await resolveDocRefs(docData, collectionName);
  }
  return docData;
}

function convertDateFields(document, collectionName) {
  const schema = masterSchema.collections[collectionName];
  if (!schema || !schema.fields) return document;
  Object.entries(schema.fields).forEach(([field, fieldSchema]) => {
    if (
      fieldSchema.type === 'date' &&
      document[field] &&
      typeof document[field] === 'object' &&
      'seconds' in document[field]
    ) {
      document[field] = new Date(document[field].seconds * 1000);
    }
  });
  return document;
}

async function resolveDocRefs(document, collectionName) {
  const schema = masterSchema.collections[collectionName];
  if (!schema || !schema.fields) return document;
  
  const docRefFields = Object.keys(schema.fields).filter(
    field => schema.fields[field].type === 'docRef' && document[field]
  );
  
  await Promise.all(
    docRefFields.map(async (field) => {
      const refCollection = schema.fields[field].collection;
      const resolved = await fetchDocument(refCollection, document[field], false);
      document[field] = resolved;
    })
  );
  return document;
}

function undoResolveDocRefs(document, collectionName) {
  const schema = masterSchema.collections[collectionName];
  if (!schema || !schema.fields) return document;
  
  for (const field in schema.fields) {
    if (schema.fields[field].type === 'docRef' && document[field] && typeof document[field] === 'object') {
      document[field] = document[field].id;
    }
  }
  return document;
}

function removeEmptyFields(obj, deleteEmptyFields = true) {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      key,
      (deleteEmptyFields && (value == null || value === '' || value === undefined)) ?
        deleteField() : value
    ])
  );
}

function prepareDocumentData(document, collectionName, deleteEmptyFields = true) {
  // Replace any resolved docRefs with just their IDs
  const updatedDocument = undoResolveDocRefs({ ...document }, collectionName);
  // Remove any existing id; Firestore auto-generates ids on creation and we avoid updating it on save.
  const { id, ...filteredDocument } = updatedDocument;
  // Remove any fields that are empty
  return removeEmptyFields(filteredDocument, deleteEmptyFields);
}
