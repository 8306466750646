// React
import React, { useState, useCallback, useMemo } from 'react';
// Redux
import { useSelector } from 'react-redux';
// Chakra
import {
  Button, FormControl, FormLabel,
  Modal, ModalBody, ModalCloseButton, ModalContent,
  ModalFooter, ModalHeader, ModalOverlay,
  VStack,
} from '@chakra-ui/react';
// MdM
import { createDocument, saveDocument } from '../../../../../../data/documents';
import taskSchema from '../../../../../../data/schemas/task';
import { renderEditableField } from '../../../../../../common/utils/renderEditableField';
import CommentInput from '../../../../../common/CommentInput';

const TaskStateTransitionModal = ({ isOpen, onClose, task, transition, onTaskUpdate }) => {
  /* ------------------------------ Hooks ------------------------------ */
  const { user } = useSelector((state) => state.user);
  const [formData, setFormData] = useState({});
  const [comment, setComment] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  /* ------------------------------ Callbacks ------------------------------ */
  const handleModalClose = useCallback(() => {
    setFormData({});
    setComment('');
    onClose();
  }, [onClose]);

  const handleFieldChange = useCallback((field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  }, []);

  const isValid = useCallback(() => {
    // Check required fields
    if (transition?.requiredFields) {
      const missingFields = transition.requiredFields.some(field => {
        const value = formData[field];
        if (value === 0) return false; // allow zero
        if (value === null || value === undefined) return true;
        if (typeof value === 'string' && value.trim() === '') return true;
        return !value;
      });
      if (missingFields) {
        return false;
      }
    }

    // Check required comment
    if (transition?.commentRequired && !comment.trim()) {
      return false;
    }

    return true;
  }, [transition, formData, comment]);

  const handleSubmit = useCallback(async () => {
    if (!task || !transition || !isValid()) return;

    setIsLoading(true);
    try {
      // Update task with new state and any required fields
      const taskUpdates = {
        ...task,
        state: transition.endState,
        ...formData
      };

      // Apply any transition-specific updates using the onTransition function
      if (transition.onTransition) {
        const transitionUpdates = transition.onTransition(task);
        Object.assign(taskUpdates, transitionUpdates);
      }

      // Loop through formData to accumulate values if needed
      Object.keys(formData).forEach((field) => {
        const fieldSchema = taskSchema.fields[field];
        if (fieldSchema && fieldSchema.accumulate) {
          // Convert values to numbers and accumulate, defaulting to 0 if missing.
          const prevValue = Number(task[field]) || 0;
          const newValue = Number(formData[field]) || 0;
          taskUpdates[field] = prevValue + newValue;
        }
      });

      // Save task updates
      await saveDocument('tasks', task.id, taskUpdates);

      // Use current comment state
      const commentText = comment.trim() || '-';
      await createDocument('task_comments', {
        task: task,
        user: user,
        commentText: commentText,
        timestamp: new Date(),
        transitionSrcState: transition.startState,
        transitionDestState: transition.endState,
        transitionData: formData,
      });

      // Notify parent of task update
      onTaskUpdate(taskUpdates);
      handleModalClose();
    } catch (error) {
      console.error('Error during state transition:', error);
    } finally {
      setIsLoading(false);
    }
  }, [task, transition, formData, comment, onTaskUpdate, handleModalClose, isValid, user]);

  /* ------------------------------ Render ------------------------------ */
  const submitEnabled = useMemo(() => isValid(), [isValid]);

  const transitionFields = useMemo(() => {
    if (!transition) return [];
    if (transition.optionalFields) {
      if (transition.requiredFields) {
        return transition.requiredFields.concat(transition.optionalFields);
      } else {
        return transition.optionalFields;
      }
    }
    return transition.requiredFields;
  }, [transition]);

  if (!task || !transition) return null;

  return (
    <Modal isOpen={isOpen} onClose={handleModalClose} size="xl">
      <ModalOverlay />
      <ModalContent bg="mdm_dark.100">
        <ModalHeader>{transition.action}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="stretch">
            {/* Required Fields */}
            {transitionFields?.map(field => {
              const fieldSchema = taskSchema.fields[field];
              if (!fieldSchema) return null;

              return (
                <FormControl key={field} isRequired>
                  <FormLabel>{fieldSchema.label}</FormLabel>
                  {renderEditableField(
                    field,
                    fieldSchema,
                    formData[field],
                    handleFieldChange,
                    false
                  )}
                </FormControl>
              );
            })}

            {/* Comment */}
            <FormControl isRequired={transition.commentRequired}>
              <FormLabel>
                {transition.commentRequired ? transition.commentPrompt : 'Add a comment (optional)'}
              </FormLabel>
              <CommentInput
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                onSubmit={handleSubmit}
                placeholder="Provide additional details..."
                rows={4}
              />
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={handleModalClose}>
            Cancel
          </Button>
          <Button
            colorScheme="mdm_teal"
            onClick={handleSubmit}
            isLoading={isLoading}
            isDisabled={!submitEnabled}
          >
            {transition.action}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TaskStateTransitionModal; 