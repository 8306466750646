// React
import React from 'react';
// Chakra
import { Select } from '@chakra-ui/react';
// MdM Constants and master schema
import { NONE_SENTINEL } from '../../../../common/constants.js';
import masterSchema from '../../../../data/schemas/master.js';

const EditableEnumRefField = ({ field, value, onChange, fieldSchema, isDisabled = false }) => {
  // Retrieve the enum definition from the master schema using the enum key provided in fieldSchema.
  const enumName = fieldSchema.enum;
  const enumDefinition = masterSchema.enums[enumName];

  // If the enum reference is invalid, show an error message.
  if (!enumName || !enumDefinition) {
    return <div>Invalid enum reference: {enumName}</div>;
  }

  // Convert the enum values object into an array of option objects.
  // Each option has an 'id' (the key) and a 'label' (the descriptive string).
  const options = enumDefinition.values.map((key) => ({
    id: key,
    label: key,
  }));

  // Add a "None" option at the beginning using the NONE_SENTINEL.
  if (fieldSchema.default === undefined || fieldSchema.default === null) {
    options.unshift({ id: NONE_SENTINEL, label: 'None' });
  }

  // Determine the currently selected option.
  // If the current value is an object with an 'id' use that; otherwise, use the value directly.
  const selectedId = (value && value.id) ? value.id : (value || fieldSchema.default || NONE_SENTINEL);

  return (
    <Select
      w="100%"
      value={selectedId}
      onChange={(e) => {
        const selectedValue = e.target.value;
        // Lookup the full option object for the selected value.
        const selectedOption = options.find(option => option.id === selectedValue);
        onChange(field, selectedOption.id);
      }}
      size="md"
      isDisabled={isDisabled}
    >
      {options.map((option) => (
        <option key={option.id} value={option.id}>
          {option.label}
        </option>
      ))}
    </Select>
  );
};

export default EditableEnumRefField;
