const employeeSchema = {
  version: '1.0',
  type: 'document',
  collection: 'employees',
  displayNameField: 'user.displayName',
  rolesWithAddAccess: ['admin'],
  rolesWithDeleteAccess: ['admin'],
  rolesWithViewAccess: ['admin'],
  fields: {
    user: {
      label: 'User',
      type: 'docRef',
      collection: 'users',
      displayNameField: 'displayName',
      required: true,
      rolesWithViewAccess: ['admin'],
      rolesWithEditAccess: ['admin'],
    },
    weeklyCompensation: {
      label: 'Weekly Compensation',
      type: 'number',
      required: true,
      rolesWithViewAccess: ['admin'],
      rolesWithEditAccess: ['admin'],
    },
    effectiveDate: {
      label: 'Effective Date',
      type: 'date',
      required: true,
      rolesWithViewAccess: ['admin'],
      rolesWithEditAccess: ['admin'],
    }
  }
};

export default employeeSchema; 