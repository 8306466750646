// React
import React, { useState, useEffect } from 'react';
// Chakra
import { Box, Center, HStack, Spinner, Text } from '@chakra-ui/react';
// MdM
import { NONE_SENTINEL } from '../../../../../common/constants';
import { UserIcon } from '../../../../../common/icons';
import { getDocuments } from '../../../../../data/documents';
import SelectableList from '../../../../common/SelectableList';

const UserPane = ({ selectedAssignee, setSelectedAssignee, authLoading }) => {
  /* ------------------------------ Hooks ------------------------------ */
  const [userDocs, setUserDocs] = useState([]);

  /* ------------------------------ Effects ------------------------------ */
  useEffect(() => {
    if (selectedAssignee) {
      getDocuments("users")
        .then((docs) => {
          if (docs.length > 0) {
            setUserDocs(docs);
          } else {
            setUserDocs([selectedAssignee]);
          }
        })
        .catch((error) => console.error("Error fetching users:", error));
    }
  }, [selectedAssignee]);

  /* ------------------------------ Render ------------------------------ */
  return (
    <Box w="100%" h="100%">
      {authLoading ? (
        <Center w="100%" h="100%">
          <Spinner />
        </Center>
      ) : (
        <SelectableList
          listElements={[
            {
              key: NONE_SENTINEL,
              displayComponent: <Text fontWeight="bold" align="center">Unassigned</Text>,
              original: null,
            },
            ...userDocs.map((doc) => ({
              key: doc.id,
              displayComponent: (
                <HStack spacing={2} w="100%">
                  <Box as={UserIcon} mr={2} />
                  <Text>{doc.displayName}</Text>
                </HStack>
              ),
              original: doc,
            })),
          ]}
          selectedElementKey={selectedAssignee?.id || NONE_SENTINEL}
          onElementClick={setSelectedAssignee}
        />
      )}
    </Box>
  );
};

export default UserPane; 