// React
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

// Chakra UI
import { Box, useTheme } from '@chakra-ui/react';

/**
 * SquareFillIndicator component that displays percentage as 1-5 squares
 * @param {Object} props - Component props
 * @param {number} props.percentage - Percentage to display (0-100)
 * @param {string} props.fillColor - Color to fill the squares (can be Chakra color token like "blue.500")
 * @param {number} props.size - Size of the entire indicator in pixels
 * @param {string} props.borderColor - Color of the square borders (can be Chakra color token like "gray.300")
 * @param {number} props.borderWidth - Width of the borders in pixels
 * @param {number} props.gap - Gap between squares in pixels
 */
const SquareFillIndicator = ({
  percentage,
  fillColor,
  size = 48,
  borderColor,
  borderWidth = 1,
  gap = 2,
}) => {
  /* ------------------------------ Hooks ------------------------------ */
  const theme = useTheme();

  /* ------------------------------ State ------------------------------ */

  // Ensure percentage is between 0 and 100
  const normalizedPercentage = Math.min(Math.max(2, Math.round(percentage)), 100);
  
  // Determine number of squares to show based on percentage range
  let numSquares;
  if (normalizedPercentage <= 20) numSquares = 1;
  else if (normalizedPercentage <= 40) numSquares = 2;
  else if (normalizedPercentage <= 60) numSquares = 3;
  else if (normalizedPercentage <= 80) numSquares = 4;
  else numSquares = 5;

  /* ------------------------------ Callbacks ------------------------------ */
  
  // Helper function to resolve color tokens to hex values
  const resolveColor = useCallback((colorToken) => {
    // If it's already a hex value or other CSS color, return as is
    if (colorToken.startsWith('#') || colorToken.startsWith('rgb') || colorToken.startsWith('hsl')) {
      return colorToken;
    }
    
    // Handle Chakra color tokens like "blue.500"
    if (colorToken.includes('.')) {
      const [colorName, shade] = colorToken.split('.');
      return theme.colors[colorName]?.[shade] || colorToken;
    }
    
    // For simple color names like "blue", try to get from theme
    return theme.colors[colorToken] || colorToken;
  }, [theme]);
  
  // Calculate the fill percentage for each square
  const calculateFillPercentages = useCallback(() => {
    // Each square represents 20%
    const squareValue = 20;
    const fillPercentages = [];
    let remainingPercentage = normalizedPercentage;
    
    for (let i = 0; i < numSquares; i++) {
      if (remainingPercentage >= squareValue) {
        fillPercentages.push(100); // Full square (100% filled)
        remainingPercentage -= squareValue;
      } else {
        // Partially filled square
        fillPercentages.push((remainingPercentage / squareValue) * 100);
        break;
      }
    }
    
    return fillPercentages;
  }, [normalizedPercentage, numSquares]);
  
  // Calculate square dimensions based on the total size and layout
  const calculateSquareDimensions = useCallback(() => {
    // Adjust for the gap between squares
    const effectiveGap = gap || 0;
    
    // Calculate the base square size based on the 5-square layout
    // This ensures all squares are the same size regardless of configuration
    const squareSize = (size - (2 * effectiveGap)) / 3;
    
    let layout = {};
    
    if (numSquares <= 1) {
      // Single square - centered
      layout = { 
        rows: 1, 
        cols: 1,
        positions: [[0, 0]] // Center position
      };
    } else if (numSquares === 2) {
      // Two squares horizontally - centered
      layout = { 
        rows: 1, 
        cols: 3,
        positions: [[0, 0.5], [0, 1.5]] // Centered horizontally
      };
    } else if (numSquares === 3) {
      // One on top, two below
      layout = { 
        rows: 2, 
        cols: 3,
        positions: [[0, 1], [1, 0.5], [1, 1.5]] // Center position on top
      };
    } else if (numSquares === 4) {
      // Two on top, two below - centered
      layout = { 
        rows: 2, 
        cols: 3,
        positions: [[0, 0.5], [0, 1.5], [1, 0.5], [1, 1.5]] // Centered in a 2x2 grid
      };
    } else if (numSquares === 5) {
      // Two on top, three below
      layout = { 
        rows: 2, 
        cols: 3,
        positions: [[0, 0.5], [0, 1.5], [1, 0], [1, 1], [1, 2]] // Two centered on top, three below
      };
    }
    
    return { squareSize, layout };
  }, [size, gap, numSquares]);
  
  /* ------------------------------ Render ------------------------------ */

  const resolvedFillColor = resolveColor(fillColor);
  const resolvedBorderColor = resolveColor(borderColor);
  const fillPercentages = calculateFillPercentages();
  const { squareSize, layout } = calculateSquareDimensions();
  
  // Render the squares
  const renderSquares = () => {
    return fillPercentages.map((fillPercentage, index) => {
      const [row, col] = layout.positions[index];
      
      // Calculate position
      const x = col * (squareSize + gap);
      const y = row * (squareSize + gap);
      
      // Calculate the fill height based on the percentage
      const fillHeight = (fillPercentage / 100) * squareSize;
      
      return (
        <g key={index}>
          {/* Square outline */}
          <rect
            x={x}
            y={y}
            width={squareSize}
            height={squareSize}
            fill="none"
            stroke={resolvedBorderColor}
            strokeWidth={borderWidth}
          />
          
          {/* Filled portion (from bottom) */}
          {fillPercentage > 0 && (
            <rect
              x={x}
              y={y + (squareSize - fillHeight)}
              width={squareSize}
              height={fillHeight}
              fill={resolvedFillColor}
            />
          )}
        </g>
      );
    });
  };
  
  // Calculate the viewBox dimensions based on layout
  const viewBoxWidth = layout.cols * squareSize + (layout.cols - 1) * gap;
  const viewBoxHeight = layout.rows * squareSize + (layout.rows - 1) * gap;
  
  // Calculate the SVG container dimensions to maintain aspect ratio
  let svgWidth = size;
  let svgHeight = size;
  
  // For a single square, make sure we don't stretch it
  if (numSquares === 1) {
    svgWidth = squareSize;
    svgHeight = squareSize;
  }
  
  return (
    <Box w={`${size}px`} h={`${size}px`} display="flex" justifyContent="center" alignItems="center">
      <svg width={svgWidth} height={svgHeight} viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}>
        {renderSquares()}
      </svg>
    </Box>
  );
};

SquareFillIndicator.propTypes = {
  percentage: PropTypes.number.isRequired,
  fillColor: PropTypes.string,
  size: PropTypes.number,
  borderColor: PropTypes.string,
  borderWidth: PropTypes.number,
  gap: PropTypes.number,
};

export default SquareFillIndicator;