// React
import React, { useEffect, useCallback, useState } from 'react';
// Redux
import { useSelector } from 'react-redux';
// Firebase
import { where } from 'firebase/firestore';
// MdM
import { createDocument } from '../../../../../data/documents';
import { NONE_SENTINEL } from '../../../../../common/constants';
import DocList from '../../../../common/docs/docList/DocList';
import tasksConfig from '../DesignerTasksConfig';

const TaskListPane = ({
  selectedAssignee, selectedProject, tasksListLoading, selectedTask, onTaskSelect, setIsLoading
}) => {
  /* ------------------------------ Hooks ------------------------------ */
  const { user } = useSelector((state) => state.user);
  const [includeDoneTasks, setIncludeDoneTasks] = useState(false);
  const {
    listConfig,
    setListConfig,
    listDocuments: taskDocs,
    isLoading: isLoadingTaskDocs,
    fetchListDocuments: fetchTaskDocs,
  } = tasksListLoading;

  const addDisabled = !selectedProject || (
    user?.role !== 'admin' &&
    user?.role !== 'lead designer' &&
    selectedAssignee?.id !== user?.id
  );

  /* ------------------------------ Callbacks ------------------------------ */
  const onNewTaskAdded = useCallback(async (newTask) => {
    const newTaskCommentDoc = {
      task: newTask,
      user: user,
      commentText: '-',
      timestamp: new Date(),
    };
    if (newTask.assignee?.id === NONE_SENTINEL) {
      await createDocument('task_comments', {
        ...newTaskCommentDoc,
        transitionDestState: 'Created',
      });
    } else {
      await createDocument('task_comments', {
        ...newTaskCommentDoc,
        transitionSrcState: 'Created',
        transitionDestState: newTask.state,
        transitionData: { assignee: newTask.assignee.displayName },
      });
    }
  }, [user]);

  /* ------------------------------ Effects ------------------------------ */
  // Pass loading state to parent
  useEffect(() => {
    if (setIsLoading) {
      setIsLoading(isLoadingTaskDocs);
    }
  }, [isLoadingTaskDocs, setIsLoading]);

  // Update tasks filter and set list config whenever selected assignee or project changes
  useEffect(() => {
    if (selectedAssignee === undefined) return;
    const assignee = !selectedAssignee ? { id: NONE_SENTINEL } : selectedAssignee;
    const queryConstraints = [
      where('assignee', '==', assignee.id),
      !includeDoneTasks && where('state', '!=', 'Done'),
      selectedProject && where('project', '==', selectedProject.id),
    ].filter(Boolean);
    const newListConfig = {
      ...tasksConfig.tasks,
      queryConstraints: queryConstraints
    };
    setListConfig(newListConfig);
  }, [selectedAssignee, selectedProject, setListConfig, includeDoneTasks]);

  // Update the selected task when taskDocs change
  useEffect(() => {
    if (!selectedTask && taskDocs.length > 0) {
      // Only select first task if no task is currently selected
      onTaskSelect(taskDocs[0]);
    } else if (selectedTask && taskDocs.length > 0) {
      // If there's a selected task, try to find its updated version
      const updatedTask = taskDocs.find((doc) => doc.id === selectedTask.id);
      if (!updatedTask) {
        // If the selected task no longer exists in the list, select the first task
        onTaskSelect(taskDocs[0]);
      }
    } else if (taskDocs.length === 0 && selectedTask) {
      // Only clear selection if there was a previously selected task
      onTaskSelect(null);
    }
  }, [taskDocs, selectedTask, onTaskSelect]);

  /* ------------------------------ Render ------------------------------ */
  return (
    <>
      <DocList
        listDocuments={taskDocs}
        listConfig={listConfig}
        isLoading={isLoadingTaskDocs}
        fetchListDocuments={fetchTaskDocs}
        selectedDocument={selectedTask}
        setSelectedDocument={onTaskSelect}
        defaultFields={{
          state: selectedAssignee ? 'Assigned' : 'Created',
        }}
        fixedFields={selectedAssignee ? {
          project: selectedProject,
          client: selectedProject?.client,
          assignee: selectedAssignee,
        } : {
          project: selectedProject,
          client: selectedProject?.client,
          assignee: selectedAssignee,
          state: 'Created',
        }}
        addDisabled={addDisabled}
        onNewDocAdded={onNewTaskAdded}
        filterCallback={setIncludeDoneTasks}
        filterLabel="Include completed tasks"
        filterChecked={includeDoneTasks}
      />
    </>
  );
};

export default TaskListPane; 