// icons.js

import { BiSidebar } from "react-icons/bi";
import { FaSignOutAlt } from 'react-icons/fa';
import {
    FiChevronLeft, FiList, FiCheckSquare,
    FiHome, FiUser, FiFolder, FiPlus, FiTrash,
    FiX, FiSend, FiBarChart2, FiEdit, FiCheck,
} from "react-icons/fi";
import { MdBusiness } from "react-icons/md";
import { FaEye, FaEyeSlash } from "react-icons/fa";

export const AddIcon = FiPlus;
export const HomeIcon = FiHome;
export const UserIcon = FiUser;
export const SignOutIcon = FaSignOutAlt;
export const SidebarIcon = BiSidebar;
export const CollapseMenuIcon = FiChevronLeft;
export const ListIcon = FiList;
export const TasksIcon = FiCheckSquare;
export const DashboardIcon = FiBarChart2;
export const ClientsIcon = FiUser;
export const ProjectsIcon = FiFolder;
export const VendorsIcon = MdBusiness;
export const EyeIcon = FaEye;
export const EyeSlashIcon = FaEyeSlash;
export const TrashIcon = FiTrash;
export const CloseIcon = FiX;
export const SendIcon = FiSend;
export const DraftIcon = FiEdit;
export const EditIcon = FiEdit;
export const CheckIcon = FiCheck;