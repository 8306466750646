// React
import React from 'react';
// Chakra UI
import { Box, HStack, Text, Badge } from '@chakra-ui/react';
// MdM
import { ClientsIcon, ProjectsIcon } from '../../../../common/icons';
import clientSchema from '../../../../data/schemas/client';
import projectSchema from '../../../../data/schemas/project';
import userSchema from '../../../../data/schemas/user';
const SelectorListElement = ({ Icon, label }) => {
  /* ------------------------------ Render ------------------------------ */
  return (
    <HStack spacing={2} w="100%">
      <Box as={Icon} mr={2} />
      <Text>{label}</Text>
    </HStack>
  );
};

const UserDocumentListElement = ({ document }) => {
  /* ------------------------------ Render ------------------------------ */
  return (
    <HStack spacing={2} w="100%">
      <Box as={ClientsIcon} />
      <Text flex="1">{document.displayName}</Text>
      <Text flex="1">{document.email}</Text>
      <Text flex="1">{document.role}</Text>
    </HStack>
  );
};

const ClientDocumentListElement = ({ document }) => {
  /* ------------------------------ Render ------------------------------ */
  return (
    <HStack spacing={2} w="100%">
      <Box as={ClientsIcon} mr={2} />
      <Text>
        {document.displayName}
        {document.companyName && document.companyName !== document.displayName
          ? ` (${document.companyName})`
          : ''}
      </Text>
    </HStack>
  );
};

const ProjectDocumentListElement = ({ document }) => {
  /* ------------------------------ Render ------------------------------ */
  const stateColorScheme = document.state === 'Active' ? 'green' : 'gray';
  
  return (
    <HStack spacing={2} w="100%">
      <Box as={ProjectsIcon} />
      <Text flex="1">{document.displayName}</Text>
      <Box as={ClientsIcon} mr={2} color="gray.400" />
      {document.client ? (
        <Text flex="1" color="gray.400">{document.client.displayName}</Text>
      ) : (
        <Text flex="1" color="red.500" fontWeight="bold">Project is missing a client</Text>
      )}
      <Badge w="70px" colorScheme={stateColorScheme} textAlign="center">
        {document.state || 'Active'}
      </Badge>
    </HStack>
  );
};

const listOptions = [
  {
    key: 'users',
    collection: 'users',
    sortField: 'displayName',
    listSelectorComponent: <SelectorListElement Icon={ClientsIcon} label="Users" />,
    docDisplayFormat: UserDocumentListElement,
    docSchema: userSchema,
    addDisabled: true,
  },
  {
    key: 'clients',
    collection: 'clients',
    sortField: 'displayName',
    listSelectorComponent: <SelectorListElement Icon={ClientsIcon} label="Clients" />,
    docDisplayFormat: ClientDocumentListElement,
    docSchema: clientSchema,
  },
  {
    key: 'projects',
    collection: 'projects',
    sortField: 'client.displayName',
    listSelectorComponent: <SelectorListElement Icon={ProjectsIcon} label="Projects" />,
    docDisplayFormat: ProjectDocumentListElement,
    docSchema: projectSchema,
  },
];

export default listOptions;
