import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    actualUser: null,
    user: null,
    loading: true,
    error: null
  },
  reducers: {
    setActualUser: (state, action) => {
      state.actualUser = action.payload;
      // When setting actual user initially, also set as current user
      if (!state.user) {
        state.user = action.payload;
      }
      state.loading = false;
    },
    setCurrentUser: (state, action) => {
      state.user = action.payload;
      state.loading = false;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    clearUser: (state) => {
      state.actualUser = null;
      state.user = null;
      state.loading = false;
      state.error = null;
    }
  }
});

export const { setActualUser, setCurrentUser, setLoading, setError, clearUser } = userSlice.actions;

export default userSlice.reducer; 