export const getCurrentMonthDateRange = () => {
  const now = new Date();
  const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
  const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59, 999);
  return { startOfMonth, endOfMonth };
};

// Get the current and previous month's date range for task fetching
export const getExtendedDateRange = () => {
  const now = new Date();
  // Start from the beginning of the previous month
  const startOfPreviousMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
  // End at the end of the current month
  const endOfCurrentMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59, 999);
  return { startOfPreviousMonth, endOfCurrentMonth };
};