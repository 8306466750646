// React
import React, { useState, useEffect } from 'react';
// Redux
import { useSelector } from 'react-redux';
// Chakra
import { Box, HStack, Text, VStack } from '@chakra-ui/react';
// MdM
import { TasksIcon } from '../../../../common/icons';
import ResizeableColumns from '../../../common/ResizeableColumns';
import useDocListLoading from '../../../common/docs/useDocListLoading';
import UserPane from './panes/UserPane';
import ProjectPane from './panes/ProjectPane';
import TaskListPane from './panes/TaskListPane';
import TaskDetailsPane from './panes/TaskDetailsPane/TaskDetailsPane';

const TasksPage = () => {
  /* ------------------------------ State ------------------------------ */
  const { user } = useSelector((state) => state.user);
  const [colWidths, setColWidths] = useState(null);
  const [selectedAssignee, setSelectedAssignee] = useState(undefined);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const [isTaskLoading, setIsTaskLoading] = useState(false);
  const tasksListLoading = useDocListLoading();
  const { updateDocInList } = tasksListLoading;

  /* ------------------------------ Callbacks ------------------------------ */
  const handleTaskUpdate = (updatedDoc) => {
    setSelectedTask(updatedDoc);
    updateDocInList(updatedDoc);
  };

  /* ------------------------------ Effects ------------------------------ */
  useEffect(() => {
    if (user) {
      setSelectedAssignee(user);
    }
  }, [user]);

  useEffect(() => {
    setSelectedTask(null);
  }, [selectedProject]);

  /* ------------------------------ Render ------------------------------ */
  return (
    <>
      <ResizeableColumns
        initialWidthPercents={[20, 20, 25, 35]}
        colWidths={colWidths}
        onWidthsChange={setColWidths}
      >
        <VStack spacing={0}>
          <Box
            w="100%"
            borderBottom="4px"
            borderColor="mdm_dark.200"
            p={4}
          >
            <HStack 
              align="center"
              justifyContent="center"
              width="100%"
            >
              <TasksIcon size={24} />
              <Text fontSize="xl" fontWeight="bold">
                Task Editor
              </Text>
            </HStack>
          </Box>
          <UserPane 
            selectedAssignee={selectedAssignee}
            setSelectedAssignee={setSelectedAssignee}
          />
        </VStack>
        <ProjectPane
          selectedProject={selectedProject}
          onProjectSelect={setSelectedProject}
        />
        <TaskListPane
          selectedAssignee={selectedAssignee}
          selectedProject={selectedProject}
          tasksListLoading={tasksListLoading}
          selectedTask={selectedTask}
          onTaskSelect={setSelectedTask}
          setIsLoading={setIsTaskLoading}
        />
        <TaskDetailsPane
          selectedTask={selectedTask} 
          isLoading={isTaskLoading}
          onTaskUpdate={handleTaskUpdate} 
        />
      </ResizeableColumns>
    </>
  );
};

export default TasksPage;
