// Schemas
import userSchema from './user';
import employeeSchema from './employee';
import projectSchema from './project';
import clientSchema from './client';
import taskSchema from './task';
import taskCommentSchema from './taskComment';

// Enums
import taskState from './taskState';
import taskCategory from './taskCategory';
import userRole from './userRole';
import projectState from './projectState';

// Note that these must match collection names, so we use snake case
const masterSchema = {
  collections: {
    users: userSchema,
    employees: employeeSchema,
    projects: projectSchema,
    clients: clientSchema,
    tasks: taskSchema,
    task_comments: taskCommentSchema,
  },
  enums: {
    TaskState: taskState.TaskState,
    TaskCategory: taskCategory.TaskCategory,
    UserRole: userRole.UserRole,
    ProjectState: projectState.ProjectState,
  }
};

export default masterSchema;