// React
import { useEffect } from 'react';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// Firebase
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';
// MdM
import { getDocumentById } from '../../data/documents';
import { setActualUser, setCurrentUser, setError, clearUser } from '../../redux/slices/userSlice';

export const useUser = () => {
  const dispatch = useDispatch();
  const { actualUser, user, loading, error } = useSelector((state) => state.user);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser?.uid) {
        try {
          const userDoc = await getDocumentById('users', firebaseUser.uid);
          dispatch(setActualUser(userDoc));
          
          // Only set the currentUser if it's not already set (initial load)
          if (!user) {
            dispatch(setCurrentUser(userDoc));
          }
        } catch (error) {
          console.error('Error fetching user document:', error);
          dispatch(setError(error.message));
        }
      } else {
        dispatch(clearUser());
      }
    });

    return () => unsubscribe();
  }, [dispatch, user]);

  return { actualUser, user, loading, error };
};

export default useUser; 