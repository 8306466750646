// React
import React, { useState, useEffect } from 'react';
// Chakra
import { Select, Spinner } from '@chakra-ui/react';
// MdM
import { NONE_SENTINEL } from '../../../../common/constants';
import { getDocuments } from '../../../../data/documents.js';

const EditableDocRefField = ({ field, value, onChange, fieldSchema, isDisabled = false }) => {
  /* ------------------------------ Hooks ------------------------------ */
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  /* ------------------------------ Effects ------------------------------ */
  useEffect(() => {
    const loadOptions = async () => {
      try {
        const fetchedOptions = await getDocuments(fieldSchema.collection);
        const sortedOptions = fetchedOptions.sort((a, b) =>
          a[fieldSchema.displayNameField].localeCompare(b[fieldSchema.displayNameField])
        );
        sortedOptions.unshift({
          id: NONE_SENTINEL,
          [fieldSchema.displayNameField]: "None",
        });
        setOptions(sortedOptions);
      } catch (error) {
        console.error('Error loading options:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadOptions();
  }, [fieldSchema.collection, fieldSchema.displayNameField]);

  /* ------------------------------ Render ------------------------------ */
  if (isLoading) return <Spinner size="sm" />;
  
  return (
    <Select
      w="100%"
      value={value ? value.id : NONE_SENTINEL}
      onChange={(e) => {
        const selectedId = e.target.value;
        // Lookup the full object from the options array based on the selected id.
        const selectedOption = options.find(option => option.id === selectedId);
        onChange(field, selectedOption);
      }}
      size="md"
      isDisabled={isDisabled}
    >
      {options.map((option) => (
        <option key={option.id} value={option.id}>
          {option[fieldSchema.displayNameField]}
        </option>
      ))}
    </Select>
  );
};

export default EditableDocRefField;
