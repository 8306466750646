// React
import React from 'react';
// Chakra UI
import { Box, HStack, Text, Badge, Tooltip } from '@chakra-ui/react';
// Icons
import { ProjectsIcon, TasksIcon, DraftIcon } from '../../../../common/icons';
// Schemas
import taskSchema from '../../../../data/schemas/task';
// Visuals
import SquareFillIndicator from '../../../../pages/common/visuals/SquareFillIndicator';
// Utils
import { weeksPerMonth } from '../../../../common/utils/calcTaskPoints';

// Component to render an element in a selector list
const SelectorListElement = ({ Icon, label }) => {
  return (
    <HStack spacing={2} w="100%">
      <Box as={Icon} mr={2} />
      <Text>{label}</Text>
    </HStack>
  );
};

// Component to render a task document list element
const TaskDocumentListElement = ({ document }) => {
  // Mapping state values to chakra colors.
  const stateColorMapping = {
    'Created': 'orange.500',
    'Assigned': 'purple.500',
    'In Progress': 'blue.500',
    'In Review': 'yellow.500',
    'Done': 'green.500',
  };

  // Function to calculate target days from points
  const calculateTargetDays = (points) => {
    if (!points) return 0;
    return (points * weeksPerMonth * 5) / 100;
  };
  
  return (
    <HStack spacing={4} w="100%">
      <Box as={TasksIcon} />
      <Text flex="1">{document.title}</Text>
      <Badge bg={stateColorMapping[document.state]} w="100px" textAlign="center">
        {document.state}
      </Badge>
      <Tooltip
        label={document.targetHours ? `Target: ${calculateTargetDays(document.points).toFixed(1)} Days` : 'Draft'}
        placement="top"
      >
        <Box>
          {document.targetHours ? (
            <SquareFillIndicator 
              percentage={(document.points || 0) * weeksPerMonth} 
              fillColor="cyan.500"
              borderColor="cyan.500"
            />
          ) : (
            <Box width="48px" height="48px" display="flex" alignItems="center" justifyContent="center">
              <Box as={DraftIcon} color="yellow.500" />
            </Box>
          )}
        </Box>
      </Tooltip>
    </HStack>
  );
};

// Component to render a project document list element with bold project name
const ProjectDocumentListElement = ({ document }) => {
  return (
    <HStack spacing={2} w="100%">
      <Box as={ProjectsIcon} mr={2} />
      <Text flex="1">
        {document.client?.displayName}: <Text as="span" fontWeight="bold">{document.displayName}</Text>
      </Text>
    </HStack>
  );
};

const tasksConfig = {
  projects: {
    key: 'projects',
    collection: 'projects',
    sortField: 'client.displayName',
    listSelectorComponent: <SelectorListElement Icon={ProjectsIcon} label="Projects" />,
    docDisplayFormat: ProjectDocumentListElement,
  },
  tasks: {
    key: 'tasks',
    collection: 'tasks',
    sortField: 'title',
    listSelectorComponent: <SelectorListElement Icon={TasksIcon} label="Tasks" />,
    docDisplayFormat: TaskDocumentListElement,
    docSchema: taskSchema,
  },
};

export default tasksConfig;
