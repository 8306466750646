// React
import React, { useCallback, useState, useEffect } from 'react';
// Chakra
import {
  Box, Flex, Text, Tooltip, Spinner,
} from '@chakra-ui/react';
// Firebase
import { where } from 'firebase/firestore';
// MdM
import { getDocuments } from '../../../../../data/documents';
import { getCurrentMonthDateRange, getExtendedDateRange } from '../../../../../common/utils/dates';

const ProductivityBar = ({ user }) => {
  /* ------------------------------ Hooks ------------------------------ */
  const [loading, setLoading] = useState(true);
  const [taskData, setTaskData] = useState(null);
  const [error, setError] = useState(null);

  // Colors for different task categories based on the schema
  const categoryColors = {
    'Design': 'green.500',
    'Project Management': 'blue.500',
    'Client Marketing': 'purple.500',
    'MdM Marketing': 'orange.500',
    'Documentation': 'yellow.500',
    'Training': 'teal.500',
    'Networking': 'cyan.500',
    'Site Visit': 'pink.500',
    'Other': 'gray.500',
  };

  const assignedColor = 'gray.500';
  const inProgressColor = 'gray.600';
  const inReviewColor = 'gray.700';
  const thresholdColor = 'teal.500';
  
  /* ------------------------------ Callbacks ------------------------------ */
  const processTasks = useCallback((tasks) => {
    const completedTasksByCategory = {};
    const assignedPoints = { total: 0 };
    const inProgressPoints = { total: 0 };
    const inReviewPoints = { total: 0 };
    let totalPoints = 0;
    
    // Get current month's date range for filtering
    const { startOfMonth, endOfMonth } = getCurrentMonthDateRange();
    
    tasks.forEach(task => {
      const category = task.category || 'Other';
      const points = task.points || 0;
      
      // Using the correct task state from the schema
      if (task.state === 'Done') {
        // Only include completed tasks if they were completed in the current month
        // (ignore tasks completed in previous months)
        if (!task.completedDate || task.completedDate >= startOfMonth) {
          // Add points to the appropriate category for completed tasks
          if (!completedTasksByCategory[category]) {
            completedTasksByCategory[category] = 0;
          }
          completedTasksByCategory[category] += points;
          totalPoints += points;
        }
      } else if (task.state === 'Assigned') {
        // Only include assigned tasks if they don't have a due date or the due
        // date is before the end of the current month
        if (!task.dueDate || task.dueDate <= endOfMonth) {
          // Accumulate points for assigned tasks
          assignedPoints.total += points;
        }
      } else if (task.state === 'In Progress') {
        // Only include in-progress tasks if they don't have a due date or the
        // due date is before the end of the current month
        if (!task.dueDate || task.dueDate <= endOfMonth) {
          // Accumulate points for in progress tasks (including in review)
          inProgressPoints.total += points;
        }
      } else if (task.state === 'In Review') {
        // Only include in-progress tasks if they don't have a due date or the
        // due date is before the end of the current month
        if (!task.dueDate || task.dueDate <= endOfMonth) {
          // Accumulate points for in progress tasks (including in review)
          inReviewPoints.total += points;
        }
      }
    });
    
    return {
      categories: completedTasksByCategory,
      assigned: assignedPoints.total,
      inProgress: inProgressPoints.total,
      inReview: inReviewPoints.total,
      total: totalPoints,
      threshold: 100, // Target threshold
      maxBarValue: 120, // Total bar length
      overThreshold: Math.max(0, totalPoints - 100) // Amount over threshold
    };
  }, []);

  /* ------------------------------ Effects ------------------------------ */
  useEffect(() => {
    const fetchTasks = async () => {
      if (!user) return;
      
      try {
        setLoading(true);
        const { startOfPreviousMonth, endOfCurrentMonth } = getExtendedDateRange();
        
        // Get all tasks assigned to the current user from both current and previous month
        const tasks = await getDocuments('tasks', [
          where('assignee', '==', user.id),
          where('createdDate', '>=', startOfPreviousMonth),
          where('createdDate', '<=', endOfCurrentMonth)
        ]);
        
        // Process tasks to get points by category
        const processedData = processTasks(tasks);
        setTaskData(processedData);
      } catch (err) {
        console.error('Error fetching tasks:', err);
        setError('Failed to load productivity data');
      } finally {
        setLoading(false);
      }
    };

    fetchTasks();
  }, [user, processTasks]);

  /* ------------------------------ Render ------------------------------ */
  if (loading) {
    return (
      <Flex justify="center" align="center" p={4} width="100%">
        <Spinner size="md" />
      </Flex>
    );
  }

  if (error) {
    return (
      <Box p={4} bg="red.100" color="red.800" borderRadius="md">
        <Text>{error}</Text>
      </Box>
    );
  }

  if (!taskData) {
    return (
      <Box p={4}>
        <Text>No productivity data available.</Text>
      </Box>
    );
  }

  const { categories, assigned, inProgress, inReview, threshold, maxBarValue } = taskData;
  const thresholdPercentage = threshold / maxBarValue * 100;
  
  // Calculate the percentage of the month that has passed
  const getMonthProgressPercentage = () => {
    const now = new Date();
    const currentDay = now.getDate();
    const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
    return (currentDay / lastDay) * 100;
  };

  // Reusable marker components
  const MarkerLine = ({ percentage, color }) => (
    <Box
      position="absolute"
      left={`${percentage}%`}
      top="0"
      height="100%"
      width="2px"
      bg={color}
      zIndex={2}
    />
  );

  const MarkerLabel = ({ percentage, displayPercentage, label, color }) => (
    <Tooltip label={label} placement="top">
      <Box
        position="absolute"
        top="-24px"
        left={`${percentage}%`}
        transform="translateX(-24px)"
        bg={color}
        color="white"
        borderRadius="md"
        px={2}
        py={1}
        fontSize="sm"
        zIndex={2}
      >
        {`${Math.round(displayPercentage)}%`}
      </Box>
    </Tooltip>
  );

  // Helper function to render a task section
  const renderTaskSection = (key, label, points, color, currentPosition) => {
    if (points <= 0) return { section: null, newPosition: currentPosition };
    
    const pointsRounded = Math.round(points);
    const widthPercentage = (points / maxBarValue) * 100;
    const showText = widthPercentage > 2;
    
    const section = (
      <Tooltip 
        key={key} 
        label={`${label}: ${points}%`}
        placement="top"
      >
        <Flex
          position="absolute"
          left={`${currentPosition}%`}
          height="100%"
          width={`${widthPercentage}%`}
          bg={color}
          alignItems="center"
          justifyContent="center"
          color="white"
          fontWeight="bold"
          fontSize="sm"
        >
          {showText && <Text>{`${pointsRounded}%`}</Text>}
        </Flex>
      </Tooltip>
    );
    
    return { section, newPosition: currentPosition + widthPercentage };
  };
  
  // Calculate percentages for rendering bar sections
  const renderBarSections = () => {
    const sections = [];
    let currentPosition = 0;
    
    // Add sections for each category
    Object.entries(categories).forEach(([category, points]) => {
      if (points > 0) {
        const color = categoryColors[category] || 'gray.500';
        const { section, newPosition } = renderTaskSection(
          category, 
          category, 
          points, 
          color, 
          currentPosition
        );
        
        if (section) {
          sections.push(section);
          currentPosition = newPosition;
        }
      }
    });
    
    // Add section for in review tasks
    const inReviewResult = renderTaskSection(
      'inReview',
      'In Review tasks',
      inReview,
      inReviewColor,
      currentPosition
    );
    if (inReviewResult.section) {
      sections.push(inReviewResult.section);
      currentPosition = inReviewResult.newPosition;
    }
    
    // Add section for in progress tasks
    const inProgressResult = renderTaskSection(
      'inProgress',
      'In Progress tasks',
      inProgress,
      inProgressColor,
      currentPosition
    );
    if (inProgressResult.section) {
      sections.push(inProgressResult.section);
      currentPosition = inProgressResult.newPosition;
    }
    
    // Add section for assigned tasks
    const assignedResult = renderTaskSection(
      'assigned',
      'Assigned tasks',
      assigned,
      assignedColor,
      currentPosition
    );
    if (assignedResult.section) {
      sections.push(assignedResult.section);
    }
    
    return sections;
  };

  return (
    <Box width="100%" p={1}>
      <Text mb={2} fontWeight="bold">
        Monthly Productivity ({new Date().toLocaleDateString(undefined, { month: 'long', year: 'numeric' })})
      </Text>
      
      <Box 
        position="relative" 
        w="100%" 
        h="40px" 
        bg="mdm_teal.600"
        borderRadius={16}
        border="2px solid"
        borderColor="mdm_teal.400"
      >
        {/* Progress bar container with overflow hidden */}
        <Box position="relative" w="100%" h="100%" overflow="hidden" borderRadius={14}>
          {renderBarSections()}
          
          {/* Marker lines inside overflow hidden container */}
          <MarkerLine 
            percentage={thresholdPercentage}
            color={thresholdColor}
          />
          <MarkerLine 
            percentage={(threshold / maxBarValue * 100) * (getMonthProgressPercentage() / 100)}
            color="blue.500"
          />
        </Box>

        {/* Labels outside overflow hidden container */}
        <MarkerLabel 
          percentage={thresholdPercentage}
          displayPercentage={100}
          label="Monthly Target: 100%"
          color={thresholdColor}
        />
        <MarkerLabel 
          percentage={(threshold / maxBarValue * 100) * (getMonthProgressPercentage() / 100)}
          displayPercentage={getMonthProgressPercentage()}
          label={`Target for Day ${new Date().getDate()}`}
          color="blue.500"
        />
      </Box>
    </Box>
  );
};

export default ProductivityBar; 