// routes.js

// React
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

// MdM
import DesignerListsPage from '../pages/portals/designers/lists/DesignerListsPage';
import DesignerTasksPage from '../pages/portals/designers/tasks/DesignerTasksPage';
import DesignerDashboardPage from '../pages/portals/designers/dashboard/DesignerDashboardPage';
import DashboardPage from '../pages/portals/clients/DashboardPage';
import { ClientsIcon, ListIcon, TasksIcon, DashboardIcon } from './icons';

const DesignerPortal = () => (
  <Routes>
    <Route index element={<Navigate to="dashboard" replace />} />
    <Route path="dashboard" element={<DesignerDashboardPage />} />
    <Route path="tasks" element={<DesignerTasksPage />} />
    <Route path="lists" element={<DesignerListsPage />} />
    <Route path="*" element={<Navigate to="dashboard" replace />} />
  </Routes>
);

const ClientPortal = () => (
  <Routes>
    <Route index element={<DashboardPage />} />
    <Route path="*" element={<Navigate to="/" replace />} />
  </Routes>
);

const designerConfig = {
  route: {
    path: '/designers/*',
    element: <DesignerPortal />,
  },
  sidebar: [
    { icon: <DashboardIcon size={24} />, label: 'Dashboard', path: '/designers/dashboard' },
    { icon: <TasksIcon size={24} />, label: 'Task Editor', path: '/designers/tasks' },
    { icon: <ListIcon size={24} />, label: 'List Editor', path: '/designers/lists' },
  ],
  title: 'Designer Portal'
};

const clientConfig = {
  route: {
    path: '/clients/*',
    element: <ClientPortal />,
  },
  sidebar: [
    { icon: <ClientsIcon size={24} />, label: 'Dashboard', path: '/clients' },
  ],
  title: 'Client Portal'
};

const roleConfig = {
  'smm': designerConfig,
  'designer': designerConfig,
  'lead designer': designerConfig,
  'admin': designerConfig,
  'client': clientConfig,
};

export const getRoleConfig = (role) => {
  return role ? roleConfig[role.toLowerCase()] || null : null;
};
