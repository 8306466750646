// React
import React, { useEffect } from 'react';
// MdM
import DocList from '../../../../common/docs/docList/DocList';

const DocumentListPane = ({ 
  listDocuments, 
  listConfig, 
  isLoading, 
  fetchListDocuments,
  selectedDocument,
  setSelectedDocument 
}) => {
  /* ------------------------------ Effects ------------------------------ */
  useEffect(() => {
    if (listDocuments.length > 0) {
      const newSelectedDoc = listDocuments.find(doc => doc.id === selectedDocument?.id);
      setSelectedDocument(newSelectedDoc ? newSelectedDoc : listDocuments[0]);
    } else {
      setSelectedDocument(null);
    }
  }, [listDocuments, selectedDocument, setSelectedDocument]);

  /* ------------------------------ Render ------------------------------ */
  return (
    <DocList 
      listDocuments={listDocuments} 
      listConfig={listConfig} 
      isLoading={isLoading} 
      fetchListDocuments={fetchListDocuments}
      selectedDocument={selectedDocument}
      setSelectedDocument={setSelectedDocument}
      addDisabled={listConfig.addDisabled === true}
    />
  );
};

export default DocumentListPane; 