// theme.js

import { extendTheme } from '@chakra-ui/react';

const customTheme = extendTheme({
  config:{
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  colors: {
    mdm: {
      teal_dark: '#016367', // SW 6489 - Really Teal // Darker teal
      teal_main: '#3c797d', // SW 6488 - Grand Canal // Main teal
      green_dark: '#7ea298', // SW 6472 - Composed // Darker green
      green_main: '#94b2a6', // SW 9050 - Vintage Vessel // Main green
      blue_dark: '#6487b0', // SW 9065 - Perfect Periwinkle // Darker blue
      blue_main: '#97b3d0', // SW 6808 - Celestial // Main blue
      dark: '#2c2c2c',
      dark_medium: '#3c3c3c',
      medium: '#6c6c6c',
      light: '#9c9c9c',
    },
    mdm_teal: {
      50: "#e5f4f5",  // Lightest: Very light tint, almost white
      100: "#b8e0e2", // Lighter tint: Adding significant white
      200: "#8acbcc", // Light tint: Noticeable color, softened by white
      300: "#5db6b8", // Medium-light tint
      400: "#3c797d", // Base color
      500: "#316062", // Medium-dark shade: Adding some black
      600: "#254949", // Dark shade: More black
      700: "#1a3131", // Darker shade: Even more black
      800: "#101818", // Very dark: Almost black
      900: "#080c0c", // Darkest: Deep, nearly black
    },
    mdm_green: {
      50: "#edf4f2",  // Lightest: Very light tint, almost white
      100: "#d4e4de", // Lighter tint: Adding significant white
      200: "#bcd5cb", // Light tint: Softer version of the base color
      300: "#a3c5b7", // Medium-light tint
      400: "#94b2a6", // Base color
      500: "#7b9084", // Medium-dark shade: Adding some black
      600: "#61706b", // Dark shade: More black
      700: "#48514f", // Darker shade: Deeper, muted green
      800: "#2e312f", // Very dark: Almost black
      900: "#171818", // Darkest: Deep, near black
    },
    mdm_blue: {
      50: "#eff5fb",  // Lightest: Very light, almost white
      100: "#d7e4f1", // Lighter tint: Subtle, soft blue
      200: "#bed3e8", // Light tint: More noticeable, but still gentle
      300: "#a5c2de", // Medium-light tint
      400: "#97b3d0", // Base color
      500: "#7992ac", // Medium-dark shade: Adding a touch of black
      600: "#5d7085", // Darker shade: Deeper and more muted
      700: "#42505f", // Darker shade: Stronger black influence
      800: "#283039", // Very dark: Almost black
      900: "#14181c", // Darkest: Deep, near black
    },
    mdm_coral: {
      50: "#f9e8e6",  // Lightest: Very light tint, almost white
      100: "#f2c6c0", // Lighter tint: Adding significant white
      200: "#eaa399", // Light tint: Noticeable color, softened by white
      300: "#e18073", // Medium-light tint
      400: "#b8574b", // Base color
      500: "#96433a", // Medium-dark shade: Adding some black
      600: "#72322c", // Dark shade: More black
      700: "#50211d", // Darker shade: Even more black
      800: "#2e100f", // Very dark: Almost black
      900: "#170807", // Darkest: Deep, nearly black
    },
    mdm_dark: {
      100: '#4c4c4c',
      200: '#3c3c3c',
      300: '#2c2c2c', // Background color
    },
  },
  components: {
    Button: {
      variants: {
        solid: (props) => {
          const { colorScheme } = props;
            return {
            bg: `${colorScheme}.400`,
            color: "white",
            _hover: {
              bg: `${colorScheme}.300`,
            },
          }
        },
      },
    },
  },
});

export default customTheme;
