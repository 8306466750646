// React
import { useState, useCallback, useEffect } from 'react';
// MdM
import { NONE_SENTINEL } from '../../../../common/constants';

export const useAddDoc = ({ docSchema, defaultFields = {}, fixedFields = {}, onSave }) => {
  /* ------------------------------ State ------------------------------ */
  const [pendingDocData, setPendingDocData] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  /* ------------------------------ Callbacks ------------------------------ */
  const handleFieldChange = useCallback(async (field, newValue) => {
    const tempUpdatedDoc = { ...pendingDocData, [field]: newValue };
    const updatedDoc = { ...tempUpdatedDoc };
    
    if (docSchema?.fields) {
      const autofillPromises = [];
      
      Object.entries(docSchema.fields).forEach(([fieldName, fieldSchema]) => {
        if (fieldSchema.autofillTriggerField === field && fieldSchema.autofill) {
          const autofillResult = fieldSchema.autofill({ 
            ...updatedDoc, 
            ...fixedFields 
          });
          
          if (autofillResult instanceof Promise) {
            autofillPromises.push(
              autofillResult.then(value => {
                if (value !== null) {
                  updatedDoc[fieldName] = value;
                }
              })
            );
          } else if (autofillResult !== null) {
            updatedDoc[fieldName] = autofillResult;
          }
        }
      });
      
      if (autofillPromises.length > 0) {
        await Promise.all(autofillPromises);
      }
    }
    
    setPendingDocData(updatedDoc);
  }, [docSchema, fixedFields, pendingDocData]);

  const checkRequiredFields = useCallback(() => {
    if (!docSchema?.fields) return true;
    for (const [field, fieldSchema] of Object.entries(docSchema.fields)) {
      if (fieldSchema.required && (
        !pendingDocData[field] && !defaultFields[field] && !fixedFields[field]
      )) {
        return false;
      }
    }
    return true;
  }, [pendingDocData, docSchema, defaultFields, fixedFields]);

  const handleSave = useCallback(async () => {
    setIsSaving(true);
    const newDoc = { ...defaultFields, ...pendingDocData, ...fixedFields };

    if (docSchema?.fields) {
      Object.entries(docSchema.fields).forEach(([fieldName, fieldSchema]) => {
        if (fieldSchema.type === 'docRef' && !newDoc[fieldName]) {
          newDoc[fieldName] = { id: NONE_SENTINEL };
        }
      });
    }

    await onSave(newDoc);
    setIsSaving(false);
  }, [defaultFields, pendingDocData, onSave, fixedFields, docSchema]);

  const initializeDoc = useCallback(() => {
    setPendingDocData({});
    setIsSaveDisabled(true);
    
    if (docSchema?.fields) {
      const initialDoc = { ...defaultFields, ...fixedFields };
      
      Object.entries(docSchema.fields).forEach(([fieldName, fieldSchema]) => {
        if (fieldSchema.default !== undefined && !initialDoc[fieldName]) {
          initialDoc[fieldName] = fieldSchema.default;
        }
      });
      
      let hasAutofills = false;
      const autofillUpdates = {};
      const autofillPromises = [];
      
      Object.entries(docSchema.fields).forEach(([fieldName, fieldSchema]) => {
        if (fieldSchema.autofillTriggerField && fieldSchema.autofill) {
          const triggerField = fieldSchema.autofillTriggerField;
          if (initialDoc[triggerField] !== undefined) {
            const autofillResult = fieldSchema.autofill(initialDoc);
            
            if (autofillResult instanceof Promise) {
              autofillPromises.push(
                autofillResult.then(value => {
                  if (value !== null) {
                    autofillUpdates[fieldName] = value;
                    hasAutofills = true;
                  }
                })
              );
            } else if (autofillResult !== null) {
              autofillUpdates[fieldName] = autofillResult;
              hasAutofills = true;
            }
          }
        }
      });
      
      if (hasAutofills && autofillPromises.length === 0) {
        setPendingDocData(autofillUpdates);
      }
      
      if (autofillPromises.length > 0) {
        Promise.all(autofillPromises).then(() => {
          if (Object.keys(autofillUpdates).length > 0) {
            setPendingDocData(autofillUpdates);
          }
        });
      }
    }
  }, [docSchema, defaultFields, fixedFields]);

  /* ------------------------------ Effects ------------------------------ */
  useEffect(() => {
    setIsSaveDisabled(!checkRequiredFields());
  }, [pendingDocData, checkRequiredFields]);

  /* ------------------------------ Render ------------------------------ */
  return {
    pendingDocData,
    isSaving,
    isSaveDisabled,
    handleFieldChange,
    handleSave,
    initializeDoc
  };
}; 