export const filterDocs = (docSchema, docs, searchTerm) => {
  // If there is no search term, return all docs.
  if (!searchTerm) return docs;
  
  let ast;
  try {
    // Parse the search term into an AST
    ast = parseQuery(searchTerm);
  } catch (e) {
    console.error("Error parsing search term, defaulting to basic search:", e);
    // If parsing fails, fall back to a simple substring match.
    return docs.filter(doc => matchSubstring(docSchema, doc, searchTerm));
  }
  
  // Filter docs by evaluating the AST against each doc.
  return docs.filter((doc) => evaluateQuery(ast, docSchema, doc));
};

// Helper that checks if any of the searchable fields contains the substring.
function matchSubstring(docSchema, doc, term) {
  // If the search term is empty or only whitespace, match every document.
  if (!term || term.trim() === "") {
    return true;
  }
  const lowerTerm = term.toLowerCase();
  return Object.entries(docSchema.fields || {}).some(([field, fieldSchema]) => {
    if (
      (fieldSchema.type === 'text' || fieldSchema.type === 'enumRef') &&
      doc[field]
    ) {
      return doc[field].toLowerCase().includes(lowerTerm);
    } else if (
      fieldSchema.type === 'docRef' &&
      doc[field] &&
      typeof doc[field] === 'object'
    ) {
      return Object.values(doc[field]).some((refField) => {
        return (
          typeof refField === 'string' &&
          refField.toLowerCase().includes(lowerTerm)
        );
      });
    }
    return false;
  });
}

// Tokenizer for the query string. It returns tokens including parentheses and operators.
function tokenize(query) {
  // This regex matches open/close parentheses, the operators AND, OR, NOT, and any other non-whitespace text.
  const regex = /(\()|(\))|(AND|OR|NOT)|([^()\s]+)/g;
  const tokens = [];
  let match;
  while ((match = regex.exec(query)) !== null) {
    if (match[1]) {
      tokens.push(match[1]);
    } else if (match[2]) {
      tokens.push(match[2]);
    } else if (match[3]) {
      tokens.push(match[3]);
    } else if (match[4]) {
      tokens.push(match[4]);
    }
  }
  return tokens;
}

// Parser: It converts the search query string into an AST supporting NOT, AND, OR and parentheses.
// Operator precedence: NOT > AND > OR.
function parseQuery(query) {
  const tokens = tokenize(query);
  let pos = 0;
  
  function parseOr() {
    let node = parseAnd();
    while (pos < tokens.length && tokens[pos] === "OR") {
      pos++; // consume "OR"
      const right = parseAnd();
      node = { type: "or", left: node, right: right };
    }
    return node;
  }
  
  function parseAnd() {
    let node = parseUnary();
    while (pos < tokens.length && tokens[pos] === "AND") {
      pos++; // consume "AND"
      const right = parseUnary();
      node = { type: "and", left: node, right: right };
    }
    return node;
  }
  
  function parseUnary() {
    if (pos < tokens.length && tokens[pos] === "NOT") {
      pos++; // consume "NOT"
      const operand = parseUnary();
      return { type: "not", operand };
    }
    return parsePrimary();
  }
  
  function parsePrimary() {
    if (tokens[pos] === "(") {
      pos++; // consume "("
      const node = parseOr();
      if (tokens[pos] !== ")") {
        throw new Error("Expected closing parenthesis");
      }
      pos++; // consume ")"
      return node;
    }
    // If there are no tokens left (e.g. trailing operator), treat it as an empty term.
    if (pos >= tokens.length) {
      return { type: "term", value: "" };
    }
    // Otherwise, merge consecutive plain tokens into a single term preserving internal whitespace.
    let termValue = tokens[pos];
    pos++;
    while (
      pos < tokens.length &&
      tokens[pos] !== "AND" &&
      tokens[pos] !== "OR" &&
      tokens[pos] !== "NOT" &&
      tokens[pos] !== "(" &&
      tokens[pos] !== ")"
    ) {
      termValue += " " + tokens[pos];
      pos++;
    }
    return { type: "term", value: termValue };
  }
  
  const ast = parseOr();
  if (pos !== tokens.length) {
    throw new Error("Unexpected tokens remaining");
  }
  return ast;
}

// Evaluator: It walks the AST and uses matchSubstring() for each term.
function evaluateQuery(ast, docSchema, doc) {
  switch (ast.type) {
    case "term":
      return matchSubstring(docSchema, doc, ast.value);
    case "not":
      return !evaluateQuery(ast.operand, docSchema, doc);
    case "and":
      return evaluateQuery(ast.left, docSchema, doc) && evaluateQuery(ast.right, docSchema, doc);
    case "or":
      return evaluateQuery(ast.left, docSchema, doc) || evaluateQuery(ast.right, docSchema, doc);
    default:
      throw new Error(`Unknown AST node type: ${ast.type}`);
  }
}