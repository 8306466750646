// React
import React, { useState, useCallback } from 'react';
// Redux
import { useSelector } from 'react-redux';
// Chakra UI
import {
  Box, VStack, HStack, IconButton, Button, useDisclosure, Text,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, ModalCloseButton,
} from '@chakra-ui/react';
// Icons
import { CloseIcon } from '../../common/icons';

const SelectableList = ({ listElements, selectedElementKey, docSchema, onElementClick, onElementDelete }) => {
  /* ------------------------------ Hooks ------------------------------ */
  const { user } = useSelector((state) => state.user);
  const [hoveredElement, setHoveredElement] = useState(null);
  const [mouseInside, setMouseInside] = useState(false);
  const [pendingElement, setPendingElement] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  /* ------------------------------ Callbacks ------------------------------ */
  const handleContainerMouseLeave = useCallback(() => {
    setMouseInside(false);
    setHoveredElement(null);
  }, []);

  const handleDeleteClick = useCallback((e, element) => {
    e.stopPropagation();
    setPendingElement(element);
    onOpen();
  }, [onOpen]);

  const handleConfirmDelete = useCallback(() => {
    if (pendingElement) {
      onElementDelete(pendingElement.original);
    }
    setPendingElement(null);
    onClose();
  }, [pendingElement, onElementDelete, onClose]);

  const handleCancelDelete = useCallback(() => {
    setPendingElement(null);
    onClose();
  }, [onClose]);

  /* ------------------------------ Render ------------------------------ */
  return (
    <>
      <Box
        h="100%"
        overflowY="auto"
        p={2}
        onMouseEnter={() => setMouseInside(true)}
        onMouseLeave={handleContainerMouseLeave}
      >
        <VStack spacing={2} align="stretch">
          {listElements.map((listElement) => {
            const isOutlined = mouseInside
              ? hoveredElement?.key === listElement.key
              : selectedElementKey === listElement.key;
            const isSelected = selectedElementKey === listElement.key;
            return (
              <Box
                key={listElement.key}
                p={2}
                borderRadius="md"
                outline={isOutlined ? '2px solid white' : 'none'}
                bg={isSelected ? 'mdm_teal.500' : 'mdm_dark.200'}
                color="white"
                cursor="pointer"
                onClick={() => onElementClick(listElement.original)}
                onMouseEnter={() => setHoveredElement(listElement)}
                onMouseLeave={() => setHoveredElement(null)}
              >
                <HStack spacing={2} w="100%">
                  <Box flex={1}>
                    {listElement.displayComponent}
                  </Box>
                  {onElementDelete && (
                    <IconButton
                      aria-label="Delete element"
                      icon={<CloseIcon />}
                      size="sm"
                      variant="ghost"
                      onClick={(e) => handleDeleteClick(e, listElement)}
                      isDisabled={
                        docSchema.rolesWithDeleteAccess &&
                        !docSchema.rolesWithDeleteAccess.includes(user?.role)
                      }
                    />
                  )}
                </HStack>
              </Box>
            );
          })}
        </VStack>
      </Box>

      {/* Confirmation Modal */}
      <Modal isOpen={isOpen} onClose={handleCancelDelete} isCentered>
        <ModalOverlay />
        <ModalContent bg="mdm_dark.300">
          <ModalHeader>Confirm Deletion</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack>
              <Text whiteSpace={'nowrap'}>Are you sure you want to delete</Text>
              <Text fontWeight="bold" whiteSpace={'nowrap'}>
                {pendingElement?.original[docSchema.displayNameField]}
              </Text>
              <Text>?</Text>
            </HStack>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={handleCancelDelete}>
              Cancel
            </Button>
            <Button colorScheme="mdm_coral" onClick={handleConfirmDelete}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SelectableList;