// store.js

import { configureStore } from '@reduxjs/toolkit';
import fieldsConfigReducer from './slices/fieldsConfigSlice';
import userReducer from './slices/userSlice';

export const store = configureStore({
  reducer: {
    fieldsConfig: fieldsConfigReducer,
    user: userReducer,
  },
}); 