// React
import React from 'react';
// MdM
import DocDetails from '../../../../common/docs/docDetails/DocDetails';

const DocumentDetailsPane = ({ docSchema, doc, isLoading, onDocUpdate }) => {
  return (
    <DocDetails
      docSchema={docSchema}
      doc={doc}
      isLoading={isLoading}
      onDocUpdate={onDocUpdate}
    />
  );
};

export default DocumentDetailsPane; 