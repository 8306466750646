const taskCategory = {
  version: '1.0',
  type: 'enum',
  TaskCategory: {
    values: [
      'Design',
      'Project Management',
      'Client Marketing',
      'MdM Marketing',
      'Documentation',
      'Networking',
      'Site Visit',
      'Training',
      'Other',
    ],
  },
};

export default taskCategory;
