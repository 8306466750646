// React
import React, { useEffect } from 'react';
// Redux
import { useSelector } from 'react-redux';
// Chakra UI
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from '@chakra-ui/react';
// MdM
import EditableDocDetails from '../EditableDocDetails';
import { useAddDoc } from './useAddDoc';

const AddDocModal = ({ docSchema, isOpen, onClose, onSave, defaultFields = {}, fixedFields = {} }) => {
  /* ------------------------------ State ------------------------------ */
  const { user } = useSelector((state) => state.user);
  const {
    pendingDocData,
    isSaving,
    isSaveDisabled,
    handleFieldChange,
    handleSave,
    initializeDoc
  } = useAddDoc({ docSchema, defaultFields, fixedFields, onSave });

  /* ------------------------------ Effects ------------------------------ */
  useEffect(() => {
    if (isOpen) {
      initializeDoc();
    }
  }, [isOpen, initializeDoc]);

  /* ------------------------------ Render ------------------------------ */
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent bg="mdm_dark.300">
        <ModalHeader>Add Document</ModalHeader>
        <ModalBody>
          <EditableDocDetails 
            docSchema={docSchema} 
            doc={{ ...defaultFields, ...pendingDocData, ...fixedFields }} 
            onFieldChange={handleFieldChange}
            hiddenFields={
              Object.keys(docSchema.fields).filter(
                (field) => {
                  const rolesWithViewAccess = docSchema.fields[field].rolesWithViewAccess;
                  if (rolesWithViewAccess === undefined) return false;
                  return !rolesWithViewAccess.includes(user?.role);
                }
              )
            }
            disabledFields={[
              ...Object.keys(fixedFields),
              ...Object.keys(docSchema.fields).filter(
                (field) => {
                  const rolesWithAddAccess = docSchema.fields[field].rolesWithAddAccess;
                  if (rolesWithAddAccess === undefined) return false;
                  return !rolesWithAddAccess.includes(user?.role);
                }
              )
            ]}
          />
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="mdm_coral" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="mdm_teal"
            onClick={handleSave}
            disabled={isSaveDisabled || isSaving}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddDocModal;
