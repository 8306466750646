const projectSchema = {
  version: '1.0',
  type: 'document',
  collection: 'projects',
  displayNameField: 'displayName',
  rolesWithAddAccess: ['admin', 'lead designer'],
  rolesWithDeleteAccess: ['admin', 'lead designer'],
  fields: {
    client: {
      label: 'Client',
      type: 'docRef',
      collection: 'clients',
      displayNameField: 'displayName',
      required: true,
      rolesWithEditAccess: ['admin', 'lead designer'],
    },
    displayName: {
      label: 'Display Name',
      type: 'text',
      required: true,
      rolesWithEditAccess: ['admin', 'lead designer'],
    },
    description: {
      label: 'Description',
      type: 'text',
      required: false,
      rolesWithEditAccess: ['admin', 'lead designer'],
    },
    state: {
      label: 'State',
      type: 'enumRef',
      enum: 'ProjectState',
      default: 'Active',
      required: true,
      rolesWithEditAccess: ['admin', 'lead designer'],
    },
    startDate: {
      label: 'Start Date',
      type: 'date',
      required: false,
      rolesWithEditAccess: ['admin', 'lead designer'],
    },
    endDate: {
      label: 'End Date',
      type: 'date',
      required: false,
      rolesWithEditAccess: ['admin', 'lead designer'],
    }
  }
};

export default projectSchema;
