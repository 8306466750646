// React
import React, { useState } from 'react';
// Redux
import { useSelector } from 'react-redux';
// Chakra
import { Button, HStack, Tooltip, useDisclosure, Divider, Box } from '@chakra-ui/react';
// MdM
import taskWorkflow from '../../../../../../data/workflows/taskWorkflow';
import TaskStateTransitionModal from './TaskStateTransitionModal';

const TaskStateTransitions = ({ task, onTaskUpdate, isLoading }) => {
  /* ------------------------------ Hooks ------------------------------ */
  const { user } = useSelector((state) => state.user);
  const [selectedTransition, setSelectedTransition] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  /* ------------------------------ Callbacks ------------------------------ */
  const handleTransitionClick = (transition) => {
    setSelectedTransition(transition);
    onOpen();
  };

  const handleTransitionComplete = () => {
    setSelectedTransition(null);
    onClose();
  };

  /* ------------------------------ Helpers ------------------------------ */
  const getValidTransitions = () => {
    if (!task || !task.state) return [];
    
    return Object.entries(taskWorkflow.transitions)
      .filter(([key, transition]) => transition.startState === task.state)
      .map(([key, transition]) => ({
        key,
        ...transition
      }));
  };

  /* ------------------------------ Render ------------------------------ */
  if (!task || isLoading) return null;

  const validTransitions = getValidTransitions();
  if (validTransitions.length === 0) return null;

  return (
    <>
      <HStack
        width="100%"
        spacing={0}
        p={4}
        align="center"
        justify="space-between"
      >
        <Divider orientation='horizontal' borderWidth={1} borderColor="mdm_teal.500" />
        
        {validTransitions.map((transition) => {
          const isAssignedUser = transition.matchUserToAssignee ? task.assignee?.id === user?.id : true;
          const hasRequiredRole = transition.permittedUserRoles.includes(user?.role);
          const isPermitted = (isAssignedUser || user?.role === 'admin') && hasRequiredRole;

          let tooltipLabel = "";
          if (!isAssignedUser) {
            tooltipLabel = "Only the assigned user can perform this action";
          } else if (!hasRequiredRole) {
            tooltipLabel = `Only ${transition.permittedUserRoles.join(' or ')} can perform this action`;
          }

          return (
            <React.Fragment key={transition.key}>
              <Box flex={1} textAlign="center">
                <Tooltip
                  label={tooltipLabel}
                  isDisabled={isPermitted}
                >
                  <Button
                    colorScheme="mdm_teal"
                    onClick={() => handleTransitionClick(transition)}
                    isDisabled={!isPermitted}
                    width="90%"
                  >
                    {transition.action}
                  </Button>
                </Tooltip>
              </Box>
              <Divider orientation='horizontal' borderWidth={1} borderColor="mdm_teal.500" />
            </React.Fragment>
          );
        })}
      </HStack>

      <TaskStateTransitionModal
        isOpen={isOpen}
        onClose={handleTransitionComplete}
        task={task}
        transition={selectedTransition}
        onTaskUpdate={onTaskUpdate}
      />
    </>
  );
};

export default TaskStateTransitions; 