// React
import React from 'react';
// Chakra UI
import { Box, Text } from '@chakra-ui/react';

const DashboardPage = () => {
  return (
    <Box p={6}>
      <Text>Dashboard Page</Text>
    </Box>
  );
};

export default DashboardPage;
