export const taskCategoryMultipliers = {
  'Design': 100,
  'Project Management': 100,
  'Client Marketing': 65,
  'MdM Marketing': 30,
  'Documentation': 20,
  'Networking': 20,
  'Site Visit': 20,
  'Training': 10,
  'Other': 0,
};

export const weeksPerMonth = 4.4;
const employeeProfitFactor = 1.3;

// 10 vacation days, 4 holiday week days, 7 holiday days
const holidaysAndPTO = 21;

// Helper function to round to specified decimal places
const roundNearestHalf = (value) => {
  return Math.round(value * 2) / 2;
};

const calculatePayrollTaxes = (employeeSalary) => {
  // Constants
  const SOCIAL_SECURITY_RATE = 0.062; // 6.2%
  const MEDICARE_RATE = 0.0145; // 1.45%
  const ADDITIONAL_MEDICARE_THRESHOLD = 200000; // Only applies above this salary
  const ADDITIONAL_MEDICARE_RATE = 0.009; // 0.9%
  const SOCIAL_SECURITY_WAGE_BASE = 168600; // 2023 wage base

  const FUTA_WAGE_BASE = 7000;
  const FUTA_RATE = 0.006; // 0.6% after credit

  const PA_SUTA_RATE = 0.025; // 2.5% (varies by employer)
  const PA_SUTA_WAGE_BASE = 10000; // Example wage base, varies by employer

  // FICA Taxes (Social Security & Medicare)
  const socialSecurityTax = Math.min(employeeSalary, SOCIAL_SECURITY_WAGE_BASE) * SOCIAL_SECURITY_RATE;
  const medicareTax = employeeSalary * MEDICARE_RATE;
  
  // Bug fix: Additional Medicare tax only applies if salary exceeds threshold
  let additionalMedicareTax = 0;
  if (employeeSalary > ADDITIONAL_MEDICARE_THRESHOLD) {
    additionalMedicareTax = (employeeSalary - ADDITIONAL_MEDICARE_THRESHOLD) * ADDITIONAL_MEDICARE_RATE;
  }
  
  // FUTA (only on first $7,000)
  const futaTax = Math.min(employeeSalary, FUTA_WAGE_BASE) * FUTA_RATE;

  // PA SUTA (only on first $10,000)
  const sutaTax = Math.min(employeeSalary, PA_SUTA_WAGE_BASE) * PA_SUTA_RATE;

  // Total employer payroll tax burden
  const totalEmployerTaxes = socialSecurityTax + medicareTax + additionalMedicareTax + futaTax + sutaTax;

  return totalEmployerTaxes;
}

const getEmployeeWeeklyRate = (task, employeeDocs) => {
  // Check if required data exists
  if (!task || !employeeDocs || !Array.isArray(employeeDocs) || employeeDocs.length === 0) {
    console.log(`Error: Missing task or employee data`);
    return 0;
  }

  // Determine the reference date based on task state
  let referenceDate;
  if (task.state === 'Done' && task.completedDate) {
    referenceDate = new Date(task.completedDate);
  } else if (task.assignedDate) {
    referenceDate = new Date(task.assignedDate);
  } else {
    console.log(`Error: No valid reference date found, using current date`);
    referenceDate = new Date();
  }

  try {
    // Sort employee documents by effective date in descending order
    const sortedEmployees = [...employeeDocs].sort((a, b) => {
      const dateA = new Date(a.effectiveDate);
      const dateB = new Date(b.effectiveDate);
      return dateB - dateA; // Descending order
    });
    
    // Find the most recent employee document that was effective before or on the reference date
    for (const employee of sortedEmployees) {
      const effectiveDate = employee.effectiveDate;
      if (effectiveDate <= referenceDate) {
        return employee.weeklyCompensation;
      }
    }

    // If no applicable entry found but employees exist, use earliest entry
    if (sortedEmployees.length > 0) {
      const earliestEmployee = [...sortedEmployees].sort((a, b) => {
        const dateA = new Date(a.effectiveDate);
        const dateB = new Date(b.effectiveDate);
        return dateA - dateB;
      })[0];
      console.log(`Error: No applicable entry found, using earliest: ${earliestEmployee.weeklyCompensation}`);
      return earliestEmployee.weeklyCompensation;
    }
  } catch (error) {
    console.log(`Error in getEmployeeWeeklyRate: ${error.message}`);
  }

  return 0;
};

const calcEmployeeMonthlyProductivityTarget = (employeeMonthlyRate) => {
  const payrollTaxes = calculatePayrollTaxes(employeeMonthlyRate);
  const productivityTarget = (employeeMonthlyRate + payrollTaxes) * employeeProfitFactor;
  
  return productivityTarget;
};

export const calcTaskPoints = (task, employeeDocs) => {
  
  // Check if task has required properties
  if (!task || !task.category || !task.targetHours) {
    return 0;
  }
  
  // Check if category exists in multipliers
  if (!taskCategoryMultipliers[task.category]) {
    task.category = 'Other';
  }
  
  const taskValue = task.targetHours * taskCategoryMultipliers[task.category];
  const weeklyRate = getEmployeeWeeklyRate(task, employeeDocs);
  const monthlyRate = weeklyRate * weeksPerMonth;
  
  // Calculate the cost of holidays and PTO distributed monthly
  const dailyRate = weeklyRate / 5; // Assuming 5-day work week
  const yearlyPTOCost = dailyRate * holidaysAndPTO;
  const monthlyPTOCost = yearlyPTOCost / 12;
  
  // Add the monthly PTO cost to the monthly rate
  const adjustedMonthlyRate = monthlyRate + monthlyPTOCost;
  
  const productivityTarget = calcEmployeeMonthlyProductivityTarget(adjustedMonthlyRate);
  
  // Avoid division by zero
  if (productivityTarget <= 0) {
    return 0;
  }
  
  return roundNearestHalf(100 * taskValue / productivityTarget);
};