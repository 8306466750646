// React
import React from 'react';
// Chakra
import {
  Box, Flex, Text, Tooltip, Spinner, VStack, Grid,
} from '@chakra-ui/react';
// MdM
import useMonthlyProductivity from './useMonthlyProductivity';

const MonthlyProductivityChart = ({ user }) => {
  const { loading, monthlyData, error } = useMonthlyProductivity(user?.id);

  // Colors for different task categories based on the schema (same as ProductivityBar)
  const categoryColors = {
    'Design': 'green.500',
    'Project Management': 'blue.500',
    'Client Marketing': 'purple.500',
    'MdM Marketing': 'orange.500',
    'Documentation': 'pink.500',
    'Training': 'teal.500',
    'Networking': 'cyan.500',
    'Other': 'gray.500',
  };

  const thresholdColor = 'teal.500';
  const overThresholdColor = 'green.500';
  const underThresholdColor = 'blue.500';
  const chartHeightPixels = 200;
  const threshold = 100; // Target threshold per month
  const chartMaxValue = 120;
  
  // Calculate the height of each bar based on the points
  const getBarHeight = (points) => {
    return (points / chartMaxValue) * 100;
  };
  
  if (loading) {
    return (
      <Flex justify="center" align="center" p={4} width="100%">
        <Spinner size="md" />
      </Flex>
    );
  }

  if (error) {
    return (
      <Box p={4} bg="red.100" color="red.800" borderRadius="md">
        <Text>{error}</Text>
      </Box>
    );
  }

  if (!monthlyData || monthlyData.length === 0) {
    return (
      <Box p={4}>
        <Text>No monthly productivity data available.</Text>
      </Box>
    );
  }

  return (
    <Box width="100%" p={4}>
      <Text mb={4} fontWeight="bold">
        Monthly Productivity (Past 12 Months)
      </Text>
      
      <VStack>
        <Box 
          position="relative" 
          width="100%" 
          height={chartHeightPixels}
          px={4}
          borderRadius={16}
          border="2px solid"
          borderColor="mdm_teal.400"
        >
          {/* Threshold line */}
          <Box
            position="absolute"
            left="0"
            right="0"
            top={`${100 - getBarHeight(threshold)}%`}
            height="2px"
            bg={thresholdColor}
            zIndex={2}
          />
          
          {/* Threshold label */}
          <Tooltip label="Monthly Target: 100%" placement="top">
            <Box
              position="absolute"
              top={`${100 - getBarHeight(threshold) - 6}%`}
              left="-25px"
              bg={thresholdColor}
              color="white"
              borderRadius="md"
              px={2}
              py={1}
              fontSize="sm"
              zIndex={2}
            >
              {"100%"}
            </Box>
          </Tooltip>
          
          {/* Chart grid */}
          <Grid 
            templateColumns={`repeat(${monthlyData.length}, 1fr)`} 
            gap={8} 
            height="100%"
            alignItems="flex-end"
          >
            {monthlyData.map((month, index) => {
              // Calculate difference from threshold
              const roundedTotal = Math.round(month.total);
              const difference = month.total - threshold;
              const roundedDifference = Math.round(difference);
              const isOverThreshold = difference >= 0;
              const differenceLabel = isOverThreshold ? `+${roundedDifference}%` : `${roundedTotal}%`;
              const differenceColor = isOverThreshold ? overThresholdColor : underThresholdColor;
              
              return (
                  <Box
                    key={index}
                    height="100%"
                    justify="flex-end"
                    position="relative"
                  >
                    {/* Difference label - only show if total is not zero */}
                    {month.total > 0 && (
                      <Box
                        position="absolute"
                        top={`${100 - getBarHeight(month.total) - (chartHeightPixels / 12)}%`}
                        left="50%"
                        transform="translateX(-50%)"
                        bg={differenceColor}
                        color="white"
                        borderRadius="md"
                        px={2}
                        py={0.5}
                        fontSize="sm"
                        fontWeight="bold"
                        zIndex={3}
                        boxShadow="md"
                        minWidth="30px"
                        textAlign="center"
                      >
                        {differenceLabel}
                      </Box>
                    )}
                    
                    {/* Bar container */}
                    <Box position="relative" w="100%" h="100%">
                      {/* Render bar segments for each category */}
                      {Object.entries(month.categories).map(([category, points], catIndex) => {
                        const roundedPoints = Math.round(points);
                        const categoryPercentage = getBarHeight(points);
                        const previousCategories = Object.entries(month.categories)
                          .slice(0, catIndex)
                          .reduce((sum, [_, catPoints]) => sum + getBarHeight(catPoints), 0);
                        
                        return (
                          <Tooltip 
                            key={`${index}-${category}`} 
                            label={`${category}: ${roundedPoints}%`}
                            placement="top"
                          >
                            <Box
                              position="absolute"
                              bottom={`${previousCategories}%`}
                              height={`${categoryPercentage}%`}
                              width="100%"
                              bg={categoryColors[category] || 'gray.500'}
                            />
                          </Tooltip>
                        );
                      })}
                    </Box>
                  </Box>
                );
              })}
            </Grid>
          </Box>

          {/* Month labels */}
          <Box w="100%" px={4}>
            <Grid 
              templateColumns={`repeat(${monthlyData.length}, 1fr)`} 
              gap={8} 
              height="100%"
              alignItems="flex-end"
            >
              {monthlyData.map((month, index) => {
                return (
                  <Box key={index} align="center">
                    {month.month}
                  </Box>
                );
              })}
            </Grid>
          </Box>
        </VStack>
      </Box>
  );
};

export default MonthlyProductivityChart; 