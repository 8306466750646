// React
import { useState, useEffect, useCallback } from 'react';
// Firebase
import { where } from 'firebase/firestore';
// MdM
import { getDocuments } from '../../../../../data/documents';

const useMonthlyProductivity = (userId) => {
  /* ------------------------------ Hooks ------------------------------ */
  const [loading, setLoading] = useState(true);
  const [monthlyData, setMonthlyData] = useState([]);
  const [error, setError] = useState(null);

  /* ------------------------------ Functions ------------------------------ */
  // Get the past 12 months date ranges
  const getPast12MonthsDateRanges = useCallback(() => {
    const ranges = [];
    const now = new Date();
    
    for (let i = 0; i < 12; i++) {
      const year = now.getFullYear();
      const month = now.getMonth() - i;
      
      // Handle negative months by adjusting the year
      const adjustedYear = month < 0 ? year - 1 : year;
      const adjustedMonth = month < 0 ? 12 + month : month;
      
      const startOfMonth = new Date(adjustedYear, adjustedMonth, 1);
      const endOfMonth = new Date(adjustedYear, adjustedMonth + 1, 0, 23, 59, 59, 999);
      
      ranges.push({
        startOfMonth,
        endOfMonth,
        monthName: startOfMonth.toLocaleDateString(undefined, { month: 'short', year: '2-digit' }),
      });
    }
    
    return ranges;
  }, []);

  const processTasks = useCallback((tasks) => {
    const completedTasksByCategory = {};
    let totalPoints = 0;
    
    tasks.forEach(task => {
      const category = task.category || 'Other';
      const points = task.points || 0;
      
      // Only count completed tasks
      if (task.state === 'Done') {
        // Add points to the appropriate category
        if (!completedTasksByCategory[category]) {
          completedTasksByCategory[category] = 0;
        }
        completedTasksByCategory[category] += points;
        totalPoints += points;
      }
    });
    
    return {
      categories: completedTasksByCategory,
      total: totalPoints,
    };
  }, []);

  /* ------------------------------ Effects ------------------------------ */
  useEffect(() => {
    const fetchMonthlyTasks = async () => {
      if (!userId) return;
      
      try {
        setLoading(true);
        const monthRanges = getPast12MonthsDateRanges();
        
        // Get all tasks assigned to the current user for the past 12 months
        const startDate = monthRanges[monthRanges.length - 1].startOfMonth;
        const endDate = monthRanges[0].endOfMonth;
        
        const allTasks = await getDocuments('tasks', [
          where('assignee', '==', userId),
          where('completedDate', '>=', startDate),
          where('completedDate', '<=', endDate)
        ]);
        
        // Process tasks by month
        const monthlyProcessedData = monthRanges.map(range => {
          const { startOfMonth, endOfMonth, monthName } = range;
          
          // Filter tasks for this month
          const tasksForMonth = allTasks.filter(task => {
            const completedDate = task.completedDate instanceof Date ? 
              task.completedDate : 
              new Date(task.completedDate);
            return completedDate >= startOfMonth && completedDate <= endOfMonth;
          });
          
          // Process tasks to get points by category
          return {
            month: monthName,
            ...processTasks(tasksForMonth),
          };
        });
        
        setMonthlyData(monthlyProcessedData.reverse()); // Reverse to show oldest to newest
      } catch (err) {
        console.error('Error fetching monthly tasks:', err);
        setError('Failed to load monthly productivity data');
      } finally {
        setLoading(false);
      }
    };

    fetchMonthlyTasks();
  }, [userId, getPast12MonthsDateRanges, processTasks]);

  /* ------------------------------ Return ------------------------------ */
  return { loading, monthlyData, error };
};

export default useMonthlyProductivity; 