// React
import React, { useCallback, useState } from 'react';
// Redux
import { useSelector } from 'react-redux';
// Chakra
import { Box, Spinner } from '@chakra-ui/react';
// MdM
import { saveDocument, getDocumentById } from '../../../../data/documents.js';
import EditableDocDetails from '../EditableDocDetails';

const DocDetails = ({ docSchema, doc, isLoading, onDocUpdate, fixedFields = {} }) => {
  /* ------------------------------ Hooks ------------------------------ */
  const { user } = useSelector((state) => state.user);
  const [isSaving, setIsSaving] = useState(false);

  /* ------------------------------ Callbacks ------------------------------ */
  const onFieldChange = useCallback(async (field, newValue) => {
    if (!doc || !doc.id) return;
    
    setIsSaving(true);
    try {
      // Create a temporary updated document with the new field value
      const tempUpdatedDoc = { ...doc, [field]: newValue };
      const updatedDoc = { ...tempUpdatedDoc };
      
      // Check for any fields that have this field as an autofillTriggerField
      if (docSchema?.fields) {
        const autofillPromises = [];
        
        Object.entries(docSchema.fields).forEach(([fieldName, fieldSchema]) => {
          if (fieldSchema.autofillTriggerField === field && fieldSchema.autofill) {
            // Apply the autofill function to get the new value
            const autofillResult = fieldSchema.autofill({ 
              ...updatedDoc, 
              ...fixedFields 
            });
            
            // Check if the result is a Promise
            if (autofillResult instanceof Promise) {
              // If it's a Promise, add it to our list of promises to await
              autofillPromises.push(
                autofillResult.then(value => {
                  if (value !== null) {
                    updatedDoc[fieldName] = value;
                  }
                })
              );
            } else if (autofillResult !== null) {
              // Handle synchronous result
              updatedDoc[fieldName] = autofillResult;
            }
          }
        });
        
        // Wait for all async autofills to complete if there are any
        if (autofillPromises.length > 0) {
          await Promise.all(autofillPromises);
        }
      }
      
      // Save the updated document with autofilled values
      await saveDocument(docSchema.collection, doc.id, updatedDoc);
      
      // Fetch the fresh document to ensure we have the latest state
      const refreshedDoc = await getDocumentById(docSchema.collection, doc.id);
      if (onDocUpdate) {
        onDocUpdate(refreshedDoc);
      }
    } catch (error) {
      console.error('Error saving data:', error);
    } finally {
      setIsSaving(false);
    }
  }, [doc, docSchema, fixedFields, onDocUpdate]);

  /* ------------------------------ Render ------------------------------ */
  const showSpinner = isLoading || isSaving;
  
  return (
    <Box position="relative" w="100%" h="100%" overflowY="auto">
      <EditableDocDetails 
        docSchema={docSchema} 
        doc={doc} 
        onFieldChange={onFieldChange}
        isDisabled={showSpinner}
        hiddenFields={
          Object.keys(docSchema.fields).filter(
            (field) => {
              const rolesWithViewAccess = docSchema.fields[field].rolesWithViewAccess;
              if (rolesWithViewAccess === undefined) return false;
              return !rolesWithViewAccess.includes(user?.role);
            }
          )
        }
        disabledFields={[
          ...Object.keys(fixedFields),
          ...Object.keys(docSchema.fields).filter(
            (field) => {
              const rolesWithEditAccess = docSchema.fields[field].rolesWithEditAccess;
              if (rolesWithEditAccess === undefined) return false;
              return !rolesWithEditAccess.includes(user?.role);
            }
          )
        ]}
      />
      {showSpinner && (
        <Box
          position="absolute"
          top="0"
          left="0"
          w="100%"
          h="100%"
          bg="rgba(0,0,0,0.2)"
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex="1"
        >
          <Spinner />
        </Box>
      )}
    </Box>
  );
};

export default DocDetails;
