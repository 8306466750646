// React
import { useState, useCallback } from 'react';
// MdM
import { getDocuments } from '../../../data/documents';

const useDocListLoading = (defaultListConfig) => {
  /* ------------------------------ Hooks ------------------------------ */
  const [listConfig, setListConfig] = useState(defaultListConfig);
  const [listDocuments, setListDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  /* ------------------------------ Helpers ------------------------------ */
  // Helper function to get nested property values using dot notation.
  const getNestedValue = (doc, fieldPath) => {
    return fieldPath.split('.').reduce((acc, key) => {
      return acc && acc[key] ? acc[key] : '';
    }, doc);
  };

  /* ------------------------------ Callbacks ------------------------------ */
  const fetchListDocuments = useCallback(async () => {
    setIsLoading(true);
    if (!listConfig) return;
    try {
      const documents = await getDocuments(listConfig.collection, listConfig.queryConstraints || []);

      if (listConfig.sortField) {
        documents.sort((a, b) => {
          // Use nested value if sortField uses dot notation (e.g., "client.displayName")
          const aField = getNestedValue(a, listConfig.sortField);
          const bField = getNestedValue(b, listConfig.sortField);
          return aField.toString().localeCompare(bField.toString());
        });
      }
      setListDocuments(documents);
    } catch (error) {
      console.error('Failed to fetch list documents:', error);
    } finally {
      setIsLoading(false);
    }
  }, [listConfig]);

  const setListConfigExternal = useCallback((newList) => {
    setListConfig(newList);
    setListDocuments([]);
    setIsLoading(true);
  }, []);

  const updateDocInList = useCallback((updatedDoc) => {
    if (!listConfig) return;
    if (listConfig.queryConstraints && listConfig.queryConstraints.length > 0) {
      fetchListDocuments();
    } else {
      setListDocuments((prevDocs) =>
        prevDocs.map((doc) => (doc.id === updatedDoc.id ? updatedDoc : doc))
      );
    }
  }, [fetchListDocuments, listConfig]);

  /* ------------------------------ Return ------------------------------ */
  return {
    listConfig,
    setListConfig: setListConfigExternal,
    listDocuments,
    isLoading,
    fetchListDocuments,
    updateDocInList,
  };
};

export default useDocListLoading;
