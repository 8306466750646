// React
import React, { useCallback, useState } from 'react';
// Chakra
import { Text, Textarea } from '@chakra-ui/react';

const EditableTextBlockField = ({ field, value, onChange, isDisabled = false }) => {
  /* ------------------------------ Hooks ------------------------------ */
  const [isEditing, setIsEditing] = useState(false);
  const [editingValue, setEditingValue] = useState('');

  /* ------------------------------ Callbacks ------------------------------ */
  const onStartEdit = useCallback(() => {
    if (isDisabled) return;
    setIsEditing(true);
    setEditingValue(value != null ? value.toString() : '');
  }, [value, isDisabled]);

  /* ------------------------------ Render ------------------------------ */
  if (isEditing) {
    return (
      <Textarea
        value={editingValue}
        onChange={(e) => setEditingValue(e.target.value)}
        onBlur={() => {
          onChange(field, editingValue);
          setIsEditing(false);
        }}
        autoFocus
        size="md"
      />
    );
  }

  return (
    <Text
      size="md"
      color={isDisabled ? 'mdm.medium' : 'white'}
      py={2}
      px={4}
      border="1px solid"
      borderRadius={6}
      borderColor="mdm_dark.100"
      whiteSpace="pre-wrap"
      onMouseEnter={(e) => (e.currentTarget.style.outline = '1px solid')}
      onMouseLeave={(e) => (e.currentTarget.style.outline = 'none')}
      onClick={onStartEdit}
      w="100%"
    >
      {(!value || value === '') ? '\u00a0' : value.toString()}
    </Text>
  );
};

export default EditableTextBlockField;
