// React
import React from 'react';
// Chakra
import { Button, IconButton, Tooltip } from '@chakra-ui/react';

const SidebarButton = ({ icon, children, onClick, showText, label, isDisabled }) => {
  /* ------------------------------ Render ------------------------------ */
  return showText ? (
    <Button
      leftIcon={icon}
      variant="ghost"
      justifyContent="flex-start"
      onClick={onClick}
      isDisabled={isDisabled}
    >
      {children}
    </Button>
  ) : (
    <Tooltip label={label} placement="right">
      <IconButton
        icon={icon}
        variant="ghost"
        onClick={onClick}
        aria-label={label}
        isDisabled={isDisabled}
      />
    </Tooltip>
  );
};

export default SidebarButton;
