// React
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
// Chakra UI
import { Box, VStack, HStack, IconButton, Text } from '@chakra-ui/react';
// MdM
import {
  CollapseMenuIcon,
  SidebarIcon,
} from '../../common/icons';
import { getRoleConfig } from '../../common/routes';
import SidebarButton from './SidebarButton';

const Sidebar = ({ userRole }) => {
  /* ------------------------------ Hooks ------------------------------ */
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const sidebarRef = useRef();

  /* ------------------------------ Callbacks ------------------------------ */
  const toggleSidebar = useCallback(() => {
    setIsSidebarOpen(prev => !prev);
  }, []);

  const handleNavigation = useCallback((path) => {
    navigate(path);
    setIsSidebarOpen(false);
  }, [navigate]);

  const handleClickOutside = useCallback((event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsSidebarOpen(false);
    }
  }, []);

  /* ------------------------------ Effects ------------------------------ */
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [handleClickOutside]);

  // Use consolidated role config to retrieve sidebar items
  const roleConfig = getRoleConfig(userRole);
  const sidebarItems = roleConfig?.sidebar || [];

  /* ------------------------------ Render ------------------------------ */
  return (
    <Box
      ref={sidebarRef}
      position="fixed"
      left="0"
      bg="rgba(0, 0, 0, 0.9)"
      color="white"
      w={isSidebarOpen ? "250px" : "75px"}
      h="100%"
      transition="width 0.2s"
      zIndex="1"
    >
      <VStack align="stretch" p={4}>
        {isSidebarOpen ? (
          <HStack justifyContent="space-between" mb={4}>
            <Text fontWeight="bold" fontSize="lg" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
              {roleConfig?.title}
            </Text>
            <IconButton
              icon={<CollapseMenuIcon size={24} />}
              onClick={toggleSidebar}
              aria-label="Toggle Sidebar"
              variant="ghost"
            />
          </HStack>
        ) : (
          <IconButton
            icon={<SidebarIcon size={24} />}
            onClick={toggleSidebar}
            aria-label="Toggle Sidebar"
            variant="ghost"
            alignSelf="flex-end"
          />
        )}
        {sidebarItems.map((item, index) => (
          <SidebarButton
            key={index}
            icon={item.icon}
            onClick={() => handleNavigation(item.path)}
            showText={isSidebarOpen}
            label={item.label}
            isDisabled={item.isDisabled}
          >
            {item.label}
          </SidebarButton>
        ))}
      </VStack>
    </Box>
  );
};

export default Sidebar;