const taskState = {
  version: '1.0',
  type: 'enum',
  TaskState: {
    values: [
      'Created', 'Assigned', 'In Progress', 'In Review', 'Done'
    ]
  }
};

export default taskState;
