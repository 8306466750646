// fieldsConfigSlice.js

import { createSlice } from '@reduxjs/toolkit';

const fieldsConfigSlice = createSlice({
  name: 'fieldsConfig',
  initialState: {
    configs: {} // Will store configs by collectionName
  },
  reducers: {
    updateFieldsConfig: (state, action) => {
      const { listId, config } = action.payload;
      state.configs[listId] = config;
    },
    initializeFieldsConfig: (state, action) => {
      const { listId, config } = action.payload;
      if (!state.configs[listId]) {
        state.configs[listId] = config;
      }
    }
  }
});

export const { updateFieldsConfig, initializeFieldsConfig } = fieldsConfigSlice.actions;
export default fieldsConfigSlice.reducer; 