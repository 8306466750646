const clientSchema = {
  version: '1.0',
  type: 'document',
  collection: 'clients',
  displayNameField: 'displayName',
  rolesWithAddAccess: ['admin', 'lead designer'],
  rolesWithDeleteAccess: ['admin', 'lead designer'],
  fields: {
    displayName: {
      label: 'Display Name',
      type: 'text',
      required: true,
      rolesWithEditAccess: ['admin', 'lead designer'],
    },
    companyName: {
      label: 'Company Name',
      type: 'text',
      required: false,
      rolesWithEditAccess: ['admin', 'lead designer'],
    },
    email: {
      label: 'Email',
      type: 'text',
      required: false,
      rolesWithEditAccess: ['admin', 'lead designer'],
    },
    streetAddress: {
      label: 'Street Address',
      type: 'text',
      required: false,
      rolesWithEditAccess: ['admin', 'lead designer'],
    },
    city: {
      label: 'City',
      type: 'text',
      required: false,
      rolesWithEditAccess: ['admin', 'lead designer'],
    },
    state: {
      label: 'State',
      type: 'text',
      required: false,
      rolesWithEditAccess: ['admin', 'lead designer'],
    },
    country: {
      label: 'Country',
      type: 'text',
      required: false,
      rolesWithEditAccess: ['admin', 'lead designer'],
    },
    zipCode: {
      label: 'Zip Code',
      type: 'text',
      required: false,
      rolesWithEditAccess: ['admin', 'lead designer'],
    },
    phone: {
      label: 'Phone',
      type: 'text',
      required: false,
      rolesWithEditAccess: ['admin', 'lead designer'],
    }
  }
};

export default clientSchema;
