const taskCommentSchema = {
  version: '1.0',
  type: 'document',
  collection: 'task_comments',
  fields: {
    task: {
      label: 'Task',
      type: 'docRef',
      collection: 'tasks',
      required: true,
    },
    user: {
      label: 'User',
      type: 'docRef',
      collection: 'users',
      required: true,
    },
    commentText: {
      label: 'Comment',
      type: 'text_block',
      required: true,
    },
    timestamp: {
      label: 'Timestamp',
      type: 'date',
      required: true,
    },
    transitionSrcState: {
      label: 'Transition Source State',
      type: 'enumRef',
      enum: 'TaskState',
      required: false,
    },
    transitionDestState: {
      label: 'Transition Destination State',
      type: 'enumRef',
      enum: 'TaskState',
      required: false,
    },
    transitionData: {
      label: 'Transition Data',
      type: 'object',
      required: false,
    }
  }
};

export default taskCommentSchema; 