// React
import React from 'react';
// Chakra
import { Box, HStack, Text, VStack } from '@chakra-ui/react';
// MdM
import { renderEditableField } from '../../../common/utils/renderEditableField';

const EditableDocDetails = ({ docSchema, doc, onFieldChange, hiddenFields = [], disabledFields = [] }) => {
  if (!doc) return <Box />;
  if (!docSchema?.fields) return <Text>No document schema available</Text>;
  return (
    <VStack spacing={2} align="stretch" w="100%" h="100%" p={4}>
      {Object.entries(docSchema.fields)
        .filter(([field]) => !hiddenFields.includes(field))
        .map(([field, fieldSchema]) => (
          <HStack key={field} spacing={2} mb={2}>
            <Text fontWeight="bold" minW="200px" whiteSpace="nowrap">
              {fieldSchema.label}:
            </Text>
            <Box flex={1}>
              {renderEditableField(
                field, fieldSchema, doc[field], onFieldChange, disabledFields.includes(field)
              )}
            </Box>
          </HStack>
        ))}
    </VStack>
  );
};

export default EditableDocDetails;
