// React
import React, { useState, useEffect, useCallback } from 'react';
// Redux
import { useSelector } from 'react-redux';
// Firebase
import { where, orderBy } from 'firebase/firestore';
// Chakra
import { Box, IconButton, HStack, Text, VStack, Spinner, Tooltip } from '@chakra-ui/react';
// MdM
import { getDocuments, createDocument, removeDocument, saveDocument } from '../../../../../../data/documents';
import masterSchema from '../../../../../../data/schemas/master';
import { SendIcon, CloseIcon, EditIcon, CheckIcon } from '../../../../../../common/icons';
import CommentInput from '../../../../../common/CommentInput';

// Add a custom stringify function for comment.transitionData
const stringifyTransitionData = (data, currentUser) => {
  if (!data || !currentUser) return '';
  
  const parts = Object.entries(data)
    .filter(([key]) => {
      // Get the field schema
      const fieldSchema = masterSchema.collections.tasks.fields[key];
      if (!fieldSchema) return false;
      
      // Check if the field has view access restrictions
      if (fieldSchema.rolesWithViewAccess) {
        // If rolesWithViewAccess is defined, check if user's role is included
        return fieldSchema.rolesWithViewAccess.includes(currentUser.role);
      }
      
      // If rolesWithViewAccess is not defined, the field is visible to all roles
      return true;
    })
    .map(([key, value]) => {
      const label = masterSchema.collections.tasks.fields[key].label;
      if (value && typeof value === 'object') {
        // If value is an object, show value.id if exists; otherwise "{}"
        return `${label}: ${'displayName' in value ? value.displayName : '{}'}`;
      } else {
        return `${label}: ${value}`;
      }
    });
    
  return parts.join(', ');
};

const TaskComments = ({ task, isLoading }) => {
  /* ------------------------------ Hooks ------------------------------ */
  const { user } = useSelector((state) => state.user);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [isCommentsLoading, setIsCommentsLoading] = useState(false);
  const [isSendingComment, setIsSendingComment] = useState(false);
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editedCommentText, setEditedCommentText] = useState('');

  /* ------------------------------ Callbacks ------------------------------ */
  const handleSendComment = useCallback(async () => {
    if (!newComment.trim() || !task || !user) return;

    setIsSendingComment(true);
    try {
      await createDocument('task_comments', {
        task: task.id,
        user: user,
        commentText: newComment.trim(),
        timestamp: new Date(),
      });

      // Refresh comments
      const updatedCommentsList = await getDocuments('task_comments', [
        where('task', '==', task.id),
        orderBy('timestamp', 'desc')
      ]);
      setComments(updatedCommentsList);
      setNewComment('');
    } catch (error) {
      console.error('Error sending comment:', error);
    } finally {
      setIsSendingComment(false);
    }
  }, [newComment, task, user]);

  const handleDeleteComment = useCallback(async (commentId) => {
    try {
      await removeDocument('task_comments', commentId);
      // Refresh comments after deletion
      const updatedComments = await getDocuments('task_comments', [
        where('task', '==', task.id),
        orderBy('timestamp', 'desc')
      ]);
      setComments(updatedComments);
    } catch (error) {
      console.error('Error deleting comment:', error);
    }
  }, [task]);

  const handleStartEdit = useCallback((comment) => {
    setEditingCommentId(comment.id);
    setEditedCommentText(comment.commentText);
  }, []);

  const handleSaveEdit = useCallback(async (comment) => {
    if (!editedCommentText.trim()) return;

    try {
      const updatedComment = {
        ...comment,
        commentText: editedCommentText.trim()
      };
      await saveDocument('task_comments', comment.id, updatedComment);

      // Refresh comments after edit
      const updatedComments = await getDocuments('task_comments', [
        where('task', '==', task.id),
        orderBy('timestamp', 'desc')
      ]);
      setComments(updatedComments);
      setEditingCommentId(null);
      setEditedCommentText('');
    } catch (error) {
      console.error('Error updating comment:', error);
    }
  }, [editedCommentText, task]);

  const handleCancelEdit = useCallback(() => {
    setEditingCommentId(null);
    setEditedCommentText('');
  }, []);

  /* ------------------------------ Effects ------------------------------ */
  useEffect(() => {
    if (!task) {
      setComments([]);
      return;
    }

    setIsCommentsLoading(true);
    getDocuments('task_comments', [
      where('task', '==', task.id),
      orderBy('timestamp', 'desc')
    ])
      .then(setComments)
      .catch(console.error)
      .finally(() => setIsCommentsLoading(false));
  }, [task]);

  /* ------------------------------ Render ------------------------------ */
  if (isLoading) {
    return (
      <Box w="100%" h="200px" display="flex" justifyContent="center" alignItems="center">
        <Spinner />
      </Box>
    );
  }

  if (!task) {
    return null;
  }

  return (
    <VStack
      w="100%"
      p={2}
      spacing={0}
      align="stretch"
      bg="mdm_dark.200"
      borderRadius="md"
    >
      {/* New Comment Input at the top */}
      <HStack spacing={4} p={4} align="center">
        <CommentInput
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          onSubmit={handleSendComment}
          placeholder="Write a comment..."
          resize="none"
          rows={3}
          flex="1"
          bg="mdm_dark.300"
        />
        <IconButton
          colorScheme="mdm_teal"
          isLoading={isSendingComment}
          onClick={handleSendComment}
          isDisabled={!newComment.trim()}
          icon={<SendIcon />}
          aria-label="Send Comment"
        />
      </HStack>

      {/* Comments List */}
      <Box w="100%" flex="1" px={4} overflowY="auto">
        <VStack 
            w="100%" 
            spacing={2} 
            align="stretch"
            borderRadius="md"
        >
          {isCommentsLoading ? (
            <Box w="100%" h="100px" display="flex" justifyContent="center" alignItems="center">
                <Spinner />
            </Box>
          ) : comments.length > 0 && comments.map(comment => {
            return (
              <Box 
                key={comment.id} 
                p={3} 
                bg="mdm_dark.300" 
                borderRadius="md"
                borderLeft="4px solid"
                borderLeftColor="mdm_teal.500"
                position="relative"
              >
                <HStack position="absolute" top={2} right={2} spacing={2}>
                  {user?.id === comment.user?.id && (
                    editingCommentId === comment.id ? (
                      <>
                        <Tooltip label="Save changes" placement="top">
                          <IconButton
                            icon={<CheckIcon />}
                            size="sm"
                            onClick={() => handleSaveEdit(comment)}
                            aria-label="Save edit"
                            variant="outline"
                            colorScheme="green"
                          />
                        </Tooltip>
                        <Tooltip label="Cancel editing" placement="top">
                          <IconButton
                            icon={<CloseIcon />}
                            size="sm"
                            onClick={handleCancelEdit}
                            aria-label="Cancel edit"
                            variant="outline"
                            colorScheme="gray"
                          />
                        </Tooltip>
                      </>
                    ) : (
                      <Tooltip label="Edit comment" placement="top">
                        <IconButton
                          icon={<EditIcon />}
                          size="sm"
                          onClick={() => handleStartEdit(comment)}
                          aria-label="Edit comment"
                          variant="ghost"
                          colorScheme="mdm_teal"
                        />
                      </Tooltip>
                    )
                  )}
                  {(
                    user?.role === 'admin' ||
                    (!comment.transitionDestState && user?.id === comment.user.id)
                  ) && editingCommentId !== comment.id && (
                    <Tooltip label="Delete comment" placement="top" hasArrow>
                      <IconButton
                        icon={<CloseIcon />}
                        size="sm"
                        onClick={() => handleDeleteComment(comment.id)}
                        aria-label="Delete comment"
                        variant="ghost"
                        colorScheme="red"
                      />
                    </Tooltip>
                  )}
                </HStack>
                {comment.transitionDestState && (
                    <Text fontSize="sm" color="mdm_teal.400" mb={1}>
                      {comment.transitionSrcState} → {comment.transitionDestState}
                    </Text>
                )}
                {comment.transitionData && Object.keys(comment.transitionData).length > 0 && (
                    <Text fontSize="sm" color="mdm_teal.400" mb={1}>
                      {stringifyTransitionData(comment.transitionData, user)}
                    </Text>
                )}
                <Text fontSize="sm" color="mdm_teal.200" mb={1}>
                    {comment.user.displayName} • {comment.timestamp.toLocaleString()}
                </Text>
                {editingCommentId === comment.id ? (
                  <CommentInput
                    value={editedCommentText}
                    onChange={(e) => setEditedCommentText(e.target.value)}
                    onSubmit={() => handleSaveEdit(comment)}
                    placeholder="Edit your comment..."
                    resize="none"
                    rows={3}
                    bg="mdm_dark.400"
                  />
                ) : (
                  <Text whiteSpace="pre-line">{comment.commentText}</Text>
                )}
              </Box>
            );
          })}
        </VStack> 
      </Box>
    </VStack>
  );
};

export default TaskComments; 