// React
import React from 'react';
// Chakra
import { Textarea } from '@chakra-ui/react';

const CommentInput = ({ value, onChange, onSubmit, ...rest }) => {
  /* ------------------------------ Callbacks ------------------------------ */
  const handleKeyDown = (event) => {
    // If Enter is pressed without Shift, trigger submit
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault(); // Prevent adding a newline
      if (onSubmit) {
        onSubmit(value);
      }
    }
  };

  return (
    <Textarea
      value={value}
      onChange={onChange}
      onKeyDown={handleKeyDown}
      {...rest}
    />
  );
};

export default CommentInput;
