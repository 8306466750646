// React
import React from 'react';
// Chakra
import { Box } from '@chakra-ui/react';
// MdM
import SelectableList from '../../../../common/SelectableList';

const ListSelectorPane = ({ listOptions, listConfig, setListConfig }) => {
  return (
    <Box w="100%" h="100%">
      <SelectableList
        listElements={listOptions.map((listConfigOption) => ({
          key: listConfigOption.key,
          displayComponent: listConfigOption.listSelectorComponent,
          original: listConfigOption,
        }))}
        selectedElementKey={listConfig.key}
        onElementClick={setListConfig}
      />
    </Box>
  );
};

export default ListSelectorPane; 