// React
import React, { useCallback, useState } from 'react';
// Chakra
import { Text, Input } from '@chakra-ui/react';

const EditableNumberField = ({ field, value, onChange, isDisabled = false }) => {
  /* ------------------------------ Hooks ------------------------------ */
  const [isEditing, setIsEditing] = useState(false);
  const [editingValue, setEditingValue] = useState(null);

  /* ------------------------------ Callbacks ------------------------------ */
  const onStartEdit = useCallback(() => {
    if (isDisabled) return;
    setIsEditing(true);
    setEditingValue(value != null ? value.toString() : '');
  }, [value, isDisabled]);

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Enter') {
      onChange(field, Number(editingValue));
      setIsEditing(false);
    }
  }, [onChange, editingValue, field]);

  /* ------------------------------ Render ------------------------------ */
  if (isEditing) {
    return (
      <Input
        type="number"
        w="100%"
        value={editingValue}
        onChange={(e) => setEditingValue(e.target.value)}
        onBlur={() => {
          onChange(field, Number(editingValue));
          setIsEditing(false);
        }}
        onKeyDown={handleKeyDown}
        autoFocus
        size="md"
      />
    );
  }

  return (
    <Text
      size="md"
      py={2}
      px={4}
      color={isDisabled ? 'mdm.medium' : 'white'}
      border="1px solid"
      borderRadius={6}
      borderColor="mdm_dark.100"
      // Only apply style changes if isDisabled is false
      onMouseEnter={(e) => {
          if (!isDisabled) {
            e.currentTarget.style.outline = '1px solid';
          }
        }}
      onMouseLeave={(e) => {
          if (!isDisabled) {
            e.currentTarget.style.outline = 'none';
          }
        }}
      onClick={onStartEdit}
      w="100%"
    >
      {(!value) ? '0' : value.toString()}
    </Text>
  );
};

export default EditableNumberField;
