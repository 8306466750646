// React
import React, { useState } from 'react';
// Chakra
import { Box, Text, HStack, VStack } from '@chakra-ui/react';
// MdM
import { ListIcon } from '../../../../common/icons';
import ResizeableColumns from '../../../common/ResizeableColumns';
import useDocListLoading from '../../../common/docs/useDocListLoading';
import listOptions from './DesignerListsConfig';
import ListSelectorPane from './panes/ListSelectorPane';
import DocumentListPane from './panes/DocumentListPane';
import DocumentDetailsPane from './panes/DocumentDetailsPane';

const DesignerListsPage = () => {
  /* ------------------------------ Hooks ------------------------------ */
  const [colWidths, setColWidths] = useState(null);
  const {
    listConfig,
    setListConfig,
    listDocuments,
    isLoading,
    fetchListDocuments,
    updateDocInList,
  } = useDocListLoading(listOptions[0]);
  const [selectedDocument, setSelectedDocument] = useState(null);

  /* ------------------------------ Render ------------------------------ */
  return (
    <Box h="100%" w="100%">
      <ResizeableColumns
        initialWidthPercents={[20, 40, 40]}
        colWidths={colWidths}
        onWidthsChange={setColWidths}
      >
        <VStack spacing={0}>
          <Box
            w="100%"
            borderBottom="4px"
            borderColor="mdm_dark.200"
            p={4}
          >
            <HStack 
              align="center"
              justifyContent="center"
              width="100%"
            >
              <ListIcon size={24} />
              <Text fontSize="xl" fontWeight="bold">
                List Editor
              </Text>
            </HStack>
          </Box>
          <ListSelectorPane 
            listOptions={listOptions}
            listConfig={listConfig}
            setListConfig={setListConfig}
          />
        </VStack>

        <DocumentListPane 
          listDocuments={listDocuments}
          listConfig={listConfig}
          isLoading={isLoading}
          fetchListDocuments={fetchListDocuments}
          selectedDocument={selectedDocument}
          setSelectedDocument={setSelectedDocument}
        />

        <DocumentDetailsPane
          docSchema={listConfig.docSchema}
          doc={selectedDocument}
          isLoading={isLoading}
          onDocUpdate={updateDocInList}
        />
      </ResizeableColumns>
    </Box>
  );
};

export default DesignerListsPage;
