import { getDocuments } from '../documents';
import { calcTaskPoints } from '../../common/utils/calcTaskPoints';
import { where } from 'firebase/firestore';

const taskSchema = {
  version: '1.0',
  type: 'document',
  collection: 'tasks',
  displayNameField: 'title',
  rolesWithAddAccess: ['admin', 'lead designer', 'designer', 'smm'],
  rolesWithDeleteAccess: ['admin', 'lead designer'],
  cascadeDelete: [
    {
      collection: 'task_comments',
      field: 'task'
    }
  ],
  fields: {
    client: {
      label: 'Client',
      type: 'docRef',
      collection: 'clients',
      displayNameField: 'displayName',
      required: true,
      rolesWithEditAccess: [],
    },
    project: {
      label: 'Project',
      type: 'docRef',
      collection: 'projects',
      displayNameField: 'displayName',
      required: true,
      rolesWithEditAccess: [],
    },
    title: {
      label: 'Title',
      type: 'text',
      required: true,
      rolesWithAddAccess: ['admin', 'lead designer', 'designer', 'smm'],
      rolesWithEditAccess: ['admin', 'lead designer'],
    },
    category: {
      label: 'Category',
      type: 'enumRef',
      enum: 'TaskCategory',
      required: true,
      rolesWithAddAccess: ['admin', 'lead designer', 'designer', 'smm'],
      rolesWithEditAccess: ['admin', 'lead designer'],
    },
    description: {
      label: 'Description',
      type: 'text_block',
      required: false,
      rolesWithAddAccess: ['admin', 'lead designer', 'designer', 'smm'],
      rolesWithEditAccess: ['admin', 'lead designer'],
    },
    assignee: {
      label: 'Assignee',
      type: 'docRef',
      collection: 'users',
      displayNameField: 'displayName',
      required: false,
      rolesWithEditAccess: [],
    },
    state: {
      label: 'State',
      type: 'enumRef',
      enum: 'TaskState',
      default: 'Created',
      required: true,
      rolesWithAddAccess: ['admin', 'lead designer'],
      rolesWithEditAccess: ['admin', 'lead designer'],
    },
    hoursWorked: {
      label: 'Hours Worked',
      type: 'number',
      required: false,
      accumulate: true,
      rolesWithAddAccess: ['admin', 'lead designer'],
      rolesWithEditAccess: ['admin', 'lead designer'],
    },
    targetHours: {
      label: 'Target Hours',
      type: 'number',
      required: false,
      rolesWithViewAccess: ['admin', 'lead designer'],
      rolesWithAddAccess: ['admin', 'lead designer'],
      rolesWithEditAccess: ['admin', 'lead designer'],
    },
    points: {
      label: 'Points',
      type: 'number',
      required: false,
      autofillTriggerField: 'targetHours',
      autofill: async (doc) => {
        if (doc.state === 'Created') {
          return 0;
        }
        
        // Get all employee documents where employee.user matches the assignee ID
        const employeeDocs = await getDocuments('employees', [
          where('user', '==', doc.assignee.id)
        ]);
        
        // If no employee documents found, return 0
        if (!employeeDocs || employeeDocs.length === 0) {
          return 0;
        }
        
        // Pass all employee documents to calcTaskPoints
        return calcTaskPoints(doc, employeeDocs);
      },
      rolesWithViewAccess: ['admin', 'lead designer'],
      rolesWithAddAccess: [],
      rolesWithEditAccess: [],
    },
    createdDate: {
      label: 'Created Date',
      type: 'date',
      required: true,
      autofillTriggerField: 'state',
      autofill: (doc) => new Date(),
      rolesWithAddAccess: ['admin', 'lead designer'],
      rolesWithEditAccess: ['admin', 'lead designer'],
    },
    assignedDate: {
      label: 'Assigned Date',
      type: 'date',
      required: false,
      autofillTriggerField: 'state',
      autofill: (document) => {
        if (document.state === 'Assigned') {
          return new Date();
        }
        return null;
      },
      rolesWithAddAccess: ['admin', 'lead designer'],
      rolesWithEditAccess: ['admin', 'lead designer'],
    },
    dueDate: {
      label: 'Due Date',
      type: 'date',
      required: false,
      rolesWithAddAccess: ['admin', 'lead designer'],
      rolesWithEditAccess: ['admin', 'lead designer'],
    },
    completedDate: {
      label: 'Completed Date',
      type: 'date',
      required: false,
      rolesWithAddAccess: ['admin', 'lead designer'],
      rolesWithEditAccess: ['admin', 'lead designer'],
    }
  }
};

export default taskSchema;
