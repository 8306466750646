const taskWorkflow = {
  initialState: 'Created',
  states: ['Created', 'Assigned', 'In Progress', 'In Review', 'Done'],
  transitions: {
    'Created->Assigned': {
      startState: 'Created',
      endState: 'Assigned',
      action: 'Assign Task',
      requiredFields: ['assignee'],
      permittedUserRoles: ['admin', 'lead designer'],
      onTransition: (task) => {
        // Set assignedDate when transitioning to Assigned state
        return { assignedDate: new Date() };
      },
    },
    'Assigned->In Progress': {
      startState: 'Assigned',
      endState: 'In Progress',
      action: 'Begin Work',
      matchUserToAssignee: true,
      permittedUserRoles: ['admin', 'lead designer', 'designer', 'smm'],
    },
    'In Progress->In Progress': {
      startState: 'In Progress',
      endState: 'In Progress',
      action: 'Record Work',
      requiredFields: [],
      optionalFields: ['hoursWorked'],
      commentRequired: true,
      commentPrompt: 'Describe the work done since the last record',
      matchUserToAssignee: true,
      permittedUserRoles: ['admin', 'lead designer', 'designer', 'smm'],
    },
    'In Progress->In Review': {
      startState: 'In Progress',
      endState: 'In Review',
      action: 'Request Review',
      requiredFields: [],
      optionalFields: ['hoursWorked'],
      commentRequired: true,
      commentPrompt: 'What is completed? What should be reviewed? Include any links or email subject lines.',
      matchUserToAssignee: true,
      permittedUserRoles: ['admin', 'lead designer', 'designer', 'smm'],
    },
    'In Review->In Progress': {
      startState: 'In Review',
      endState: 'In Progress',
      action: 'Request Rework',
      commentRequired: true,
      commentPrompt: 'What needs to be reworked?',
      permittedUserRoles: ['admin', 'lead designer'],
    },
    'In Review->Done': {
      startState: 'In Review',
      endState: 'Done',
      action: 'Mark Done',
      commentRequired: true,
      commentPrompt: 'Describing feedback on task completion',
      permittedUserRoles: ['admin', 'lead designer'],
      onTransition: (task) => {
        // Set completedDate when transitioning to Done state
        return { completedDate: new Date() };
      },
    },
  },
};

export default taskWorkflow;