// ResizeableColumns.jsx

// React Imports
import React, { useRef, useEffect, useCallback } from 'react';
// MdM (Chakra UI) Imports
import { Box, HStack } from '@chakra-ui/react';

const ResizeableColumns = ({ children, initialWidthPercents, colWidths, onWidthsChange }) => {
  /* ------------------------------ Hooks ------------------------------ */
  const containerRef = useRef(null);
  // dragging: { dividerIndex, startX, initWidth1, initWidth2 }
  const [dragging, setDragging] = React.useState(null);
  const minWidth = 200;
  const dividerWidth = 4;
  const childrenArr = React.Children.toArray(children);
  const colCount = childrenArr.length;

  /* ------------------------------ Callbacks ------------------------------ */
  const onMouseDownDivider = useCallback(
    (index, e) => {
      if (!colWidths) return;
      setDragging({
        dividerIndex: index,
        startX: e.clientX,
        initWidth1: colWidths[index],
        initWidth2: colWidths[index + 1],
      });
    },
    [colWidths]
  );

  const onMouseMove = useCallback(
    e => {
      if (!dragging || !colWidths) return;
      const { dividerIndex, startX, initWidth1, initWidth2 } = dragging;
      const delta = e.clientX - startX;
      const lowerBound = minWidth - initWidth1;
      const upperBound = initWidth2 - minWidth;
      const allowedDelta = Math.min(Math.max(delta, lowerBound), upperBound);
      const newWidth1 = initWidth1 + allowedDelta;
      const newWidth2 = initWidth2 - allowedDelta;
      const newWidths = [...colWidths];
      newWidths[dividerIndex] = newWidth1;
      newWidths[dividerIndex + 1] = newWidth2;
      onWidthsChange(newWidths);
    },
    [dragging, colWidths, onWidthsChange, minWidth]
  );

  const onMouseUp = useCallback(() => {
    setDragging(null);
  }, []);

  /* ------------------------------ Effects ------------------------------ */
  useEffect(() => {
    if (containerRef.current && (!colWidths || colWidths.length !== colCount)) {
      const totalWidth = containerRef.current.clientWidth;
      const availableWidth = totalWidth - (colCount - 1) * dividerWidth;
      let initialWidths;
      if (initialWidthPercents && initialWidthPercents.length === colCount) {
        initialWidths = initialWidthPercents.map(percent => (availableWidth * percent) / 100);
      } else {
        const equalWidth = availableWidth / colCount;
        initialWidths = Array(colCount).fill(equalWidth);
      }
      onWidthsChange(initialWidths);
    }
  }, [containerRef, colCount, dividerWidth, initialWidthPercents, colWidths, onWidthsChange]);

  useEffect(() => {
    if (dragging) {
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    } else {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    }
    return () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, [dragging, onMouseMove, onMouseUp]);

  /* ------------------------------ Render ------------------------------ */
  return (
    <Box ref={containerRef} w="100%" h="100%">
      <HStack spacing={0} h="100%" w="100%">
        {childrenArr.map((child, index) => (
          <React.Fragment key={index}>
            <Box
              h="100%"
              w={colWidths && colWidths[index] ? `${colWidths[index]}px` : 'auto'}
              minW={`${minWidth}px`}
            >
              {child}
            </Box>
            {index < childrenArr.length - 1 && (
              <Box
                h="100%"
                w={`${dividerWidth}px`}
                cursor="col-resize"
                bg="mdm_dark.200"
                onMouseDown={e => onMouseDownDivider(index, e)}
              />
            )}
          </React.Fragment>
        ))}
      </HStack>
    </Box>
  );
};

export default ResizeableColumns;