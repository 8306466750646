// React
import React from 'react';
// Chakra
import { Box, VStack } from '@chakra-ui/react';
// MdM
import DocDetails from '../../../../../common/docs/docDetails/DocDetails';
import taskSchema from '../../../../../../data/schemas/task';
import TaskComments from './TaskComments';
import TaskStateTransitions from './TaskStateTransitions';

const TaskDetailsPane = ({ isLoading, selectedTask, onTaskUpdate }) => {
  return (
    <VStack h="100%" w="100%" spacing={4} align="stretch" overflowY="auto">
      <Box w="100%">
        <DocDetails
          docSchema={taskSchema}
          doc={selectedTask}
          isLoading={isLoading}
          onDocUpdate={onTaskUpdate}
          fixedFields={{
            project: selectedTask?.project,
            client: selectedTask?.client,
          }}
        />
      </Box>
      
      <Box w="100%" px={8}>
        <TaskStateTransitions
          task={selectedTask}
          onTaskUpdate={onTaskUpdate}
          isLoading={isLoading}
        />
      </Box>

      <Box w="100%" flex="1" px={8} pb={8}>
        <TaskComments 
          task={selectedTask}
          isLoading={isLoading}
        />
      </Box>
    </VStack>
  );
};

export default TaskDetailsPane; 