// React
import React, { useState, useEffect } from 'react';
// Redux
import { useSelector } from 'react-redux';
// Chakra
import {
  Box, Flex, Heading, Text, VStack, SimpleGrid, Icon,
  Stat, StatLabel, StatNumber, StatHelpText, Spinner,
  Select,
} from '@chakra-ui/react';
// Icons
import { FiBarChart2, FiCheckCircle, FiClock, FiCalendar } from 'react-icons/fi';
// Firebase
import { where } from 'firebase/firestore';
// MdM Components
import ProductivityBar from './components/ProductivityBar';
import MonthlyProductivityChart from './components/MonthlyProductivityChart';
import { getDocuments } from '../../../../data/documents';
import { getCurrentMonthDateRange, getExtendedDateRange } from '../../../../common/utils/dates';

const DesignerDashboardPage = () => {
  const { user } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState([]);
  const [error, setError] = useState(null);
  const [dashboardUser, setDashboardUser] = useState(null);
  const [users, setUsers] = useState([]);

  const showUserSelect = user?.role === 'admin' || user?.role === 'lead designer';

  // Get the current week's start and end dates
  const getCurrentWeekDateRange = () => {
    const now = new Date();
    const dayOfWeek = now.getDay(); // 0 = Sunday, 1 = Monday, etc.
    const startOfWeek = new Date(now);
    startOfWeek.setDate(now.getDate() - dayOfWeek);
    startOfWeek.setHours(0, 0, 0, 0);
    
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    endOfWeek.setHours(23, 59, 59, 999);
    
    return { startOfWeek, endOfWeek };
  };

  useEffect(() => {
    const loadUsers = async () => {
      if (showUserSelect) {
        try {
          const allUsers = await getDocuments('users');
          setUsers(allUsers);
        } catch (error) {
          console.error('Error loading users:', error);
          setError('Failed to load users');
        }
      }
    };

    loadUsers();
  }, [showUserSelect]);

  useEffect(() => {
    setDashboardUser(user);
  }, [user]);

  useEffect(() => {
    const fetchTaskStats = async () => {
      if (!dashboardUser) return;
      
      try {
        setLoading(true);
        const { startOfWeek, endOfWeek } = getCurrentWeekDateRange();
        const { startOfMonth, endOfMonth } = getCurrentMonthDateRange();
        const { startOfPreviousMonth, endOfCurrentMonth } = getExtendedDateRange();
        
        // Get all tasks assigned to the dashboardUser from both current and previous month
        const tasks = await getDocuments('tasks', [
          where('assignee', '==', dashboardUser.id),
          where('createdDate', '>=', startOfPreviousMonth),
          where('createdDate', '<=', endOfCurrentMonth)
        ]);
        
        // Filter tasks for different stats
        const tasksThisMonth = tasks.filter(task => {
          const createdDate = task.createdDate instanceof Date ? 
            task.createdDate : 
            new Date(task.createdDate);
          return createdDate >= startOfPreviousMonth && createdDate <= endOfMonth && task.completedDate >= startOfMonth;
        });
        
        const completedTasksThisMonth = tasksThisMonth.filter(task => task.state === 'Done');
        const inProgressTasks = tasks.filter(task => 
          task.state === 'In Progress' || task.state === 'In Review'
        );
        const upcomingTasks = tasks.filter(task => {
          if (task.dueDate) {
            const dueDate = task.dueDate instanceof Date ? 
              task.dueDate : 
              new Date(task.dueDate);
            return task.state !== 'Done' && dueDate >= startOfWeek && dueDate <= endOfWeek;
          }
          return false;
        });
        
        // Calculate total points for completed tasks this month
        const totalPointsThisMonth = completedTasksThisMonth.reduce(
          (sum, task) => sum + (task.points || 0), 
          0
        );
        
        // Create stats array with real data
        const statsData = [
          {
            label: 'Completed Tasks',
            value: completedTasksThisMonth.length.toString(),
            icon: FiCheckCircle,
            color: 'green.500',
            helpText: 'This month',
          },
          {
            label: 'In Progress',
            value: inProgressTasks.length.toString(),
            icon: FiClock,
            color: 'blue.500',
            helpText: 'Active tasks',
          },
          {
            label: 'Upcoming',
            value: upcomingTasks.length.toString(),
            icon: FiCalendar,
            color: 'purple.500',
            helpText: 'Due this week',
          },
          {
            label: 'Total',
            value: `${totalPointsThisMonth.toString()}%`,
            icon: FiBarChart2,
            color: 'orange.500',
            helpText: 'This month',
          },
        ];
        
        setStats(statsData);
      } catch (err) {
        console.error('Error fetching task stats:', err);
        setError('Failed to load dashboard data');
      } finally {
        setLoading(false);
      }
    };

    fetchTaskStats();
  }, [dashboardUser]);

  if (loading) {
    return (
      <Flex justify="center" align="center" height="100%" width="100%" p={10}>
        <Spinner size="xl" />
      </Flex>
    );
  }

  if (error) {
    return (
      <Box p={5} bg="red.100" color="red.800" borderRadius="md">
        <Text>{error}</Text>
      </Box>
    );
  }

  const handleUserChange = (event) => {
    const selectedUser = users.find(u => u.id === event.target.value);
    setDashboardUser(selectedUser || user);
  };

  return (
    <Box p={5} width="100%">
      <VStack spacing={6} align="stretch">
        {/* Greeting and User Selector */}
        <Flex justify="space-between" align="center">
          <VStack align="start" spacing={1}>
            <Heading size="lg">
              Welcome, {dashboardUser?.displayName || 'Designer'}
            </Heading>
          </VStack>
          {showUserSelect && (
            <Select
              value={dashboardUser?.id || ''}
              onChange={handleUserChange}
              width="200px"
              bg="mdm_teal.600"
              borderColor="mdm_teal.400"
            >
              {users.map(u => (
                <option key={u.id} value={u.id}>
                  {u.displayName}
                </option>
              ))}
            </Select>
          )}
        </Flex>

        {/* Productivity Bar */}
        <Box 
          p={4} 
          borderRadius="md" 
          bg="mdm_teal.600" 
          borderWidth="1px" 
          borderColor="mdm_teal.400"
          shadow="sm"
        >
          <ProductivityBar user={dashboardUser} />
        </Box>

        {/* Stats Cards */}
        <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={5}>
          {stats.map((stat, index) => (
            <Box
              key={index}
              p={5}
              borderRadius="md"
              bg="mdm_teal.600"
              borderWidth="1px"
              borderColor="mdm_teal.400"
              shadow="sm"
            >
              <Stat>
                <Flex justifyContent="space-between">
                  <StatLabel>{stat.label}</StatLabel>
                  <Icon as={stat.icon} color={stat.color} boxSize="1.5em" />
                </Flex>
                <StatNumber fontSize="2xl">{stat.value}</StatNumber>
                <StatHelpText>{stat.helpText}</StatHelpText>
              </Stat>
            </Box>
          ))}
        </SimpleGrid>

        {/* Monthly Productivity Chart */}
        <Box 
          p={4} 
          borderRadius="md" 
          bg="mdm_teal.600" 
          borderWidth="1px" 
          borderColor="mdm_teal.400"
          shadow="sm"
        >
          <MonthlyProductivityChart user={dashboardUser} />
        </Box>
      </VStack>
    </Box>
  );
};

export default DesignerDashboardPage;
