const userSchema = {
  version: '1.0',
  type: 'document',
  collection: 'users',
  displayNameField: 'displayName',
  rolesWithAddAccess: ['admin', 'lead designer'],
  rolesWithDeleteAccess: ['admin', 'lead designer'],
  fields: {
    displayName: {
      label: 'Display Name',
      type: 'text',
      required: true,
      rolesWithEditAccess: ['admin', 'lead designer'],
    },
    email: {
      label: 'Email',
      type: 'text',
      required: true,
      rolesWithEditAccess: [],
    },
    role: {
      label: 'Role',
      type: 'enumRef',
      enum: 'UserRole',
      required: true,
      rolesWithEditAccess: ['admin'],
    }
  }
};

export default userSchema;
