const projectState = {
  version: '1.0',
  type: 'enum',
  ProjectState: {
    values: [
      'Active', 'Inactive'
    ]
  }
};

export default projectState; 